import { CARD_TYPE } from "./Card";

export const typeMap: any = {
  [CARD_TYPE.ANSWER]: "Resposta",
  [CARD_TYPE.QUESTION]: "Pergunta",
};

export const typeChipColor: any = {
  [CARD_TYPE.ANSWER]: "primary",
  [CARD_TYPE.QUESTION]: "success",
};
