import styled from "styled-components";

export const FinishGameContainer = styled.div<{ $open?: boolean }>`
  background: white;
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  scale: ${({ $open }) => ($open ? 1 : 0)};
  transition: 300ms cubic-bezier(0.46, -0.39, 0.57, 1.32);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  box-shadow: 5px 5px 20px -5px #00000091;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  overflow: visible;
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
`;

export const FinishGameHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;
