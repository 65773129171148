import { useCallback, useState } from "react";
import { API_ROUTES } from "../../api/routes";
import { GameClient } from "../../api/GameClient";
import { AxiosError } from "axios";

export const useFinishGame = (code: string) => {
  const [error, setError] = useState<AxiosError>();

  const finishGame = useCallback(async () => {
    setError(undefined);
    try {
      const { data } = await GameClient.post(API_ROUTES.FINISH(code));
      if (data) {
        return data;
      }
    } catch (e) {
      const error = e as AxiosError<any, any>;
      error.message = error.response?.data?.message || error.message;
      setError(e as any);
    }
  }, [code]);

  return { finishGame, error };
};
