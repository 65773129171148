import React, { useEffect, useMemo, useRef, useState } from "react";
import { BoardContainer, BoardHeader } from "./Board.styles";
import { useGameContext } from "../../contexts/GameContext";
import { useSessionContext } from "../../contexts/SessionContext";
import {
  Badge,
  Button,
  CircularProgress,
  Fab,
  Typography,
  Zoom,
} from "@mui/material";
import {
  FaArrowLeft,
  FaCrown,
  FaMedal,
  FaTimes,
  FaUser,
  FaUserSlash,
} from "react-icons/fa";
import { toast } from "material-react-toastify";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes";
import AnimalBg from "../AnimalBg/AnimalBg";
import { AnimalsEnum } from "../AnimalBg/types";

const Board: React.FC = () => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const currentPlayers = useRef<{ [id: string]: string }>({});

  const {
    players,
    tableAnswers,
    votingStarted: voteStarted,
    finished,
    onlinePlayers,
  } = useGameContext();
  const { user_id } = useSessionContext();

  const userVotes = useMemo(() => {
    const allVotes: any = {};

    tableAnswers.forEach(({ votes }) => {
      votes?.forEach((user) => {
        allVotes[user] = true;
      });
    });

    return allVotes;
  }, [tableAnswers]);

  const userAnswers = useMemo(() => {
    const allAnswers: any = {};
    tableAnswers.forEach(({ user_id }) => (allAnswers[user_id || ""] = true));
    return allAnswers;
  }, [tableAnswers]);

  useEffect(() => {
    const currentIds = Object.keys(currentPlayers.current);
    for (const { id, name } of onlinePlayers) {
      if (id) {
        if (
          currentIds.length &&
          id !== user_id &&
          !currentPlayers.current[id]
        ) {
          toast.success(`${name} entrou na sala.`);
        }
        currentPlayers.current[id] = name || "";
      }
    }
    currentIds.forEach((id) => {
      if (!onlinePlayers.find((player) => id === player.id)) {
        if (id !== user_id) {
          toast.error(`${currentPlayers.current[id]} saiu sala.`);
        }
        delete currentPlayers.current[id];
      }
    });
  }, [onlinePlayers, user_id]);

  const ranking = useMemo(
    () => players.sort((a, b) => (b.points || 0) - (a.points || 0)),
    [players],
  );

  const getIconForPosition = (position: number) => {
    if (!position) {
      return <FaCrown color="orange" size={20} />;
    }
    if (position === 1) {
      return <FaMedal color="gray" size={16} />;
    }
    if (position === 2) {
      return <FaMedal color="rosybrown" size={16} />;
    }
    return <Typography color="textPrimary">{position + 1}</Typography>;
  };

  return (
    <BoardContainer $open={open || finished} $finished={finished}>
      {!finished && (
        <Zoom
          in={!open}
          timeout={100}
          unmountOnExit
          style={{
            transitionDelay: "200ms",
          }}
        >
          <Fab color="inherit" onClick={() => setOpen(true)} size="medium">
            <Badge
              variant="standard"
              color="info"
              badgeContent={onlinePlayers.length}
              style={{ cursor: "pointer" }}
            >
              <FaUser size={22} />
            </Badge>
          </Fab>
        </Zoom>
      )}

      {!finished && open && (
        <BoardHeader>
          <Typography variant="h6">Jogadores</Typography>

          <button onClick={() => setOpen(false)}>
            <FaTimes size={18} />
          </button>
        </BoardHeader>
      )}

      {finished && (
        <center>
          <Typography variant="h6">Ranking final</Typography>
        </center>
      )}

      {(open || finished) && (
        <ul style={{ flex: 1 }}>
          {ranking.map((player, position) => (
            <li key={player.id || player.name}>
              {finished ? (
                <div style={{ width: "20px", textAlign: "center" }}>
                  {getIconForPosition(position)}
                </div>
              ) : (
                <>
                  {player?.online ? (
                    <div
                      style={{
                        position: "relative",
                        color: "white",
                        opacity: !(
                          (voteStarted && userVotes[player.id || ""]) ||
                          (!voteStarted && userAnswers[player.id || ""])
                        )
                          ? 0.7
                          : 1,
                      }}
                    >
                      {!(
                        (voteStarted && userVotes[player.id || ""]) ||
                        (!voteStarted && userAnswers[player.id || ""])
                      ) && (
                        <CircularProgress
                          size={25}
                          color="inherit"
                          style={{
                            position: "absolute",
                          }}
                        />
                      )}
                      <AnimalBg
                        circle
                        dance={
                          !(
                            (voteStarted && userVotes[player.id || ""]) ||
                            (!voteStarted && userAnswers[player.id || ""])
                          )
                        }
                        size="25px"
                        name={player.avatar as AnimalsEnum}
                        color={player.color}
                      />
                    </div>
                  ) : (
                    <FaUserSlash
                      color={player.online ? "inherit" : "lightgray"}
                      size={25}
                    />
                  )}
                </>
              )}
              <Typography
                color={
                  user_id === player.id
                    ? "info"
                    : player.online
                      ? "textPrimary"
                      : "textDisabled"
                }
                variant={finished && !position ? "h6" : "body1"}
              >
                {player.name}
              </Typography>
              <Typography
                color={
                  user_id === player.id
                    ? "info"
                    : player.online
                      ? "textPrimary"
                      : "textDisabled"
                }
                variant={finished && !position ? "h6" : "body1"}
              >
                {player.points || 0}
              </Typography>
            </li>
          ))}
        </ul>
      )}

      {finished && (
        <center style={{ margin: "1rem 0" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              navigate(PATHS.GAME.HOME(), {
                replace: true,
              })
            }
            startIcon={<FaArrowLeft size={14} />}
          >
            Começar um novo jogo
          </Button>
        </center>
      )}
    </BoardContainer>
  );
};

export default Board;
