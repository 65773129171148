import React from "react";
import { AnimalBgContainer } from "./AnimalBg.styles";
import Animal from "react-animals";
import { AnimalsEnum } from "./types";
import parseCSSColor from "parse-css-color";

type AnimalBgProps = {
  name?: AnimalsEnum | string;
  color?: string;
  size?: string;
  circle?: boolean;
  dance?: boolean;
};

const AnimalBg: React.FC<AnimalBgProps> = ({
  name,
  color,
  size,
  dance,
  circle,
}) => {
  const rgb = parseCSSColor(color || "");

  const [r, g, b] = rgb?.values || [0, 0, 0];

  const invert = (r + g + b) / 3 > 180;

  return (
    <AnimalBgContainer $bgInvert={invert}>
      <Animal
        name={name}
        color={color}
        dance={dance}
        size={size}
        circle={circle}
      />
    </AnimalBgContainer>
  );
};

export default AnimalBg;
