import React, { useEffect, useMemo, useState } from "react";
import { ClockContainer } from "./Clock.styles";
import { useGameContext } from "../../contexts/GameContext";
import { Chip, Typography } from "@mui/material";
import { useSoundContext } from "../../contexts/SoundContext";
import { FaRegClock } from "react-icons/fa";

const Clock: React.FC = () => {
  const [blink, setBlink] = useState(false);
  const { voteTimeLimit, answerTimeLimit, showTime, votingStarted, settings } =
    useGameContext();
  const { playClockAudio, stopClockAudio, playBellAudio } = useSoundContext();

  const { answerTime, voteTime } = settings || {};

  const timeLimit = useMemo(
    () => ((votingStarted ? voteTime : answerTime) || 0) * 60000 + 1000,
    [votingStarted, voteTime, answerTime],
  );

  const clockTime = useMemo(
    () => (votingStarted ? voteTimeLimit : answerTimeLimit) + 1000,
    [answerTimeLimit, voteTimeLimit, votingStarted],
  );

  const shouldBlink = clockTime <= timeLimit * 0.2;

  useEffect(() => {
    if (shouldBlink) {
      const interval = setInterval(() => setBlink((b) => !b), 250);
      return () => clearInterval(interval);
    }
  }, [shouldBlink, stopClockAudio]);

  const shouldPlayAudio =
    clockTime <= timeLimit * 0.4 && clockTime > 0 && showTime;

  useEffect(() => {
    if (shouldPlayAudio) {
      playClockAudio();
    } else {
      stopClockAudio();
    }
  }, [playClockAudio, shouldPlayAudio, stopClockAudio]);

  const shouldPlayBell = showTime && clockTime <= 0;

  useEffect(() => {
    if (shouldPlayBell) {
      playBellAudio();
    }
  }, [playBellAudio, shouldPlayBell]);

  const color = useMemo(() => {
    if (clockTime > timeLimit * 0.6) {
      return "default";
    }
    if (clockTime > timeLimit * 0.4) {
      return "warning";
    }
    if (clockTime > timeLimit * 0.2) {
      return "error";
    }
    if (blink) {
      return "default";
    }
    return "error";
  }, [blink, clockTime, timeLimit]);

  const show = showTime && !!Math.round((clockTime - 1000) / 1000);

  return (
    <ClockContainer $show={show}>
      {showTime && (
        <Chip
          variant="outlined"
          color={color}
          icon={<FaRegClock size={16} />}
          label={
            <Typography>
              {new Date(clockTime > 0 ? clockTime : 1)
                .toISOString()
                .slice(14, 19)}
            </Typography>
          }
        />
      )}
    </ClockContainer>
  );
};

export default Clock;
