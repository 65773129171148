import { useCallback, useState } from "react";
import { API_ROUTES } from "../../api/routes";
import { GameClient } from "../../api/GameClient";
import { AxiosError } from "axios";
import { Card } from "../../../../api/types/Card";
import { CardData } from "../../../../api/data/types/Card";

export const usePlayCards = (code: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const [hand, setHand] = useState<Card[]>([]);

  const playCards = useCallback(
    async (cardIds: string[]) => {
      setError(undefined);
      setLoading(true);
      try {
        const { data } = await GameClient.post(API_ROUTES.PLAY(code), cardIds);
        if (data) {
          setLoading(false);
          setHand(data);
          return data as CardData[];
        }
      } catch (e) {
        setLoading(false);
        const error = e as AxiosError<any, any>;
        error.message = error.response?.data?.message || error.message;
        setError(e as any);
      }
    },
    [code]
  );

  return { playCards, loading, error, hand };
};
