import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  AnimalContainer,
  ChatContainer,
  ChatHeader,
  ChatMessagesList,
  InputContainer,
  Message,
  MessageContainer,
  MessageWrapper,
  TextInput,
} from "./Chat.styles";
import { Badge, Button, Fab, rgbToHex, Typography, Zoom } from "@mui/material";
import { FaComment, FaPaperPlane, FaTimes } from "react-icons/fa";
import { useSessionContext } from "../../contexts/SessionContext";
import { useGameContext } from "../../contexts/GameContext";
import { useSendMessage } from "../../hooks/Game/useSendMessage";
import dayjs from "dayjs";
import { useSoundContext } from "../../contexts/SoundContext";
import AnimalBg from "../AnimalBg/AnimalBg";
import { shouldShowShadow } from "./helpers";
import parseCSSColor from "parse-css-color";

const Chat: React.FC = () => {
  const ref = useRef<HTMLUListElement>(null);
  const { user_id, user } = useSessionContext();
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [lastRead, setLastRead] = useState(0);
  const lastNotification = useRef(0);
  const { code, chatMessages, finished, players } = useGameContext();
  const { playBubbleAudio } = useSoundContext();

  const { loading, sendMessage } = useSendMessage(code);

  const handleSend = async () => {
    if (message.trim() && user) {
      await sendMessage(message.trim());
      setMessage("");
    }
  };

  const [playerColors, playerAvatars] = useMemo(
    () =>
      players.reduce(
        ([colors, avatars], player) => [
          {
            ...colors,
            [player?.id || ""]: player.online
              ? rgbToHex(
                `rgb(${(parseCSSColor(player.color || "")?.values || [0, 0, 0]).join(",")})`,
              )
              : "#CCCCCC",
          },
          {
            ...avatars,
            [player?.id || ""]: player.avatar,
          },
        ],
        [{}, {}] as any,
      ),
    [players],
  );

  useEffect(() => {
    ref.current?.scrollTo({
      top: ref.current.scrollHeight,
      behavior: "smooth",
    });
  }, [chatMessages]);

  useEffect(() => {
    if (open) {
      ref.current?.scrollTo({
        top: ref.current.scrollHeight,
        behavior: "smooth",
      });
      setLastRead(chatMessages.length);
    }
  }, [chatMessages.length, open]);

  useEffect(() => {
    if (lastRead) {
      sessionStorage.setItem(`last-read-${code}`, `${lastRead}`);
    }
  }, [code, lastRead]);

  useEffect(() => {
    const lastFromSession = sessionStorage.getItem(`last-read-${code}`);
    if (lastFromSession) {
      setLastRead(+lastFromSession);
    }
  }, [code, lastRead]);

  const unread =
    chatMessages?.length - lastRead > 0 ? chatMessages?.length - lastRead : 0;
  const lastMessageUserId = [...chatMessages].pop()?.user.id;

  useEffect(() => {
    if (
      unread &&
      lastMessageUserId !== user_id &&
      !open &&
      lastNotification.current < chatMessages?.length
    ) {
      playBubbleAudio?.();
      lastNotification.current = chatMessages?.length;
    }
  }, [
    chatMessages?.length,
    lastMessageUserId,
    open,
    playBubbleAudio,
    unread,
    user_id,
  ]);

  return (
    <ChatContainer $open={open}>
      {!finished && (
        <Zoom
          in={!open}
          timeout={100}
          unmountOnExit
          style={{
            transitionDelay: "100ms",
          }}
        >
          <Fab color="inherit" onClick={() => setOpen(true)} size="medium">
            <Badge
              variant="standard"
              color="error"
              badgeContent={unread}
              showZero={false}
              style={{ cursor: "pointer" }}
            >
              <FaComment size={22} />
            </Badge>
          </Fab>
        </Zoom>
      )}

      {open && (
        <ChatHeader>
          <Typography variant="h6">Chat</Typography>

          <button onClick={() => setOpen(false)}>
            <FaTimes />
          </button>
        </ChatHeader>
      )}

      {open && (
        <>
          <ChatMessagesList ref={ref}>
            {chatMessages.map(({ user, text, date }, i) => (
              <MessageWrapper $sent={user.id === user_id} key={i}>
                <AnimalContainer>
                  {(!chatMessages?.[i + 1]?.user?.id ||
                    chatMessages[i + 1].user.id !== user.id) && (
                      <AnimalBg
                        circle
                        size="25px"
                        name={playerAvatars[user.id]}
                        color={playerColors[user.id]}
                      />
                    )}
                </AnimalContainer>
                <MessageContainer
                  $sent={user.id === user_id}
                  $isLast={
                    !chatMessages?.[i + 1]?.user?.id ||
                    chatMessages[i + 1].user.id !== user.id
                  }
                >
                  <Message>
                    {user.id !== user_id && (
                      <Typography
                        color={
                          shouldShowShadow(playerColors[user.id])
                            ? "textPrimary"
                            : playerColors[user.id]
                        }
                        variant="caption"
                        fontWeight="bold"
                      >
                        {user.name}
                      </Typography>
                    )}
                    <Typography color="textPrimary" variant="body2">
                      {text}
                    </Typography>
                  </Message>
                  {(!chatMessages?.[i + 1]?.user?.id ||
                    chatMessages[i + 1].user.id !== user.id) && (
                      <Typography color="textSecondary" variant="caption">
                        {dayjs(date).format(
                          dayjs(date).isSame(new Date(), "date")
                            ? "HH:mm"
                            : "DD/MM/YYYY HH:mm",
                        )}
                      </Typography>
                    )}
                </MessageContainer>
              </MessageWrapper>
            ))}
          </ChatMessagesList>

          <InputContainer>
            <TextInput
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Digite aqui"
              disabled={loading}
              onKeyDown={(e) => e.key === "Enter" && handleSend()}
            />

            <Button
              variant="contained"
              onClick={handleSend}
              disabled={loading || !message.trim()}
            >
              <FaPaperPlane />
            </Button>
          </InputContainer>
        </>
      )}
    </ChatContainer>
  );
};

export default Chat;
