import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { GameCard } from "../types/Card";
import { useParams } from "react-router-dom";
import { useGameState } from "../hooks/Game/useGameState";
import { useSessionContext } from "./SessionContext";
import { useNextQuestion } from "../hooks/Game/useNextQuestion";
import { User } from "../types/Session";
import { TableAnswer } from "../types/Table";
import { SettingsData } from "../types/GameState";
import { ChatMessage } from "../types/ChatMessage";

const MINUTES = 60000;

type GameContextProps = {
  code: string;
  questionCard?: GameCard;
  handCards: Array<GameCard>;
  showHand: boolean;
  selectedCards: GameCard[];
  setQuestionCard: React.Dispatch<React.SetStateAction<GameCard | undefined>>;
  setHandCards: React.Dispatch<React.SetStateAction<Array<GameCard>>>;
  setShowHand: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCards: React.Dispatch<React.SetStateAction<GameCard[]>>;
  setTableAnswers: React.Dispatch<React.SetStateAction<TableAnswer[]>>;
  setChatMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  getGameState: (code: string) => void;
  players: Partial<User>[];
  onlinePlayers: Partial<User>[];
  tableAnswers: Partial<TableAnswer>[];
  votingStarted: boolean;
  votingFinished: boolean;
  adminId?: string;
  voteStartedTime?: string;
  answerStartedTime?: string;
  settings?: SettingsData;
  answerTimeLimit: number;
  voteTimeLimit: number;
  showTime: boolean;
  finished?: boolean;
  chatMessages: ChatMessage[];
};

const GameContext = createContext<GameContextProps>({
  code: "",
  handCards: [],
  setHandCards: () => null,
  setSelectedCards: () => null,
  setQuestionCard: () => null,
  setShowHand: () => null,
  getGameState: () => null,
  setTableAnswers: () => null,
  setChatMessages: () => null,
  chatMessages: [],
  showHand: false,
  players: [],
  onlinePlayers: [],
  tableAnswers: [],
  selectedCards: [],
  votingStarted: false,
  votingFinished: false,
  showTime: false,
  finished: false,
  answerTimeLimit: 0,
  voteTimeLimit: 0,
});

export const GameContextProvider: React.FC<{
  children: Array<React.ReactNode> | React.ReactNode;
}> = ({ children }) => {
  const code = (useParams().code || "").toUpperCase();

  const [questionCard, setQuestionCard] = useState<GameCard>();
  const [handCards, setHandCards] = useState<Array<GameCard>>([]);
  const [tableAnswers, setTableAnswers] = useState<Array<TableAnswer>>([]);
  const [showHand, setShowHand] = useState(false);
  const [selectedCards, setSelectedCards] = useState<GameCard[]>([]);
  const [players, setPlayers] = useState<Partial<User>[]>([]);
  const [answerTimeLimit, setAnswerTimeLimit] = useState(0);
  const [voteTimeLimit, setVoteTimeLimit] = useState(0);
  const [showTime, setShowTime] = useState(false);

  const { nextQuestion } = useNextQuestion(code);
  const { game, getGameState, chatMessages, setChatMessages } = useGameState(
    code || "",
  );
  const { user, getSession } = useSessionContext();

  const { answerStarted, voteStarted } = game?.table || {};
  const { voteTime, answerTime } = game?.game.settings || {};

  const gameExists = !!game;

  useEffect(() => {
    getSession?.();
  }, [getSession, gameExists]);

  const onlinePlayers = useMemo(
    () => players.filter(({ online, id }) => online || id === user?.id),
    [players, user?.id],
  );

  const votingStarted = useMemo(
    () =>
      !!(
        answerStarted &&
        voteStarted &&
        new Date(answerStarted).getTime() < new Date(voteStarted).getTime()
      ),
    [answerStarted, voteStarted],
  );

  const votingFinished = useMemo(() => {
    const totalVotes = tableAnswers.reduce(
      (count: number, answer: any) => count + (answer.votes?.length || 0),
      0,
    );
    const otherPlayersLength = onlinePlayers.filter(
      ({ id }) => user?.id !== id,
    ).length;

    return (
      !!(
        totalVotes &&
        otherPlayersLength &&
        totalVotes >= otherPlayersLength + 1
      ) ||
      !!(
        votingStarted &&
        ((voteTime && voteTimeLimit < 0) ||
          !otherPlayersLength ||
          tableAnswers.length <= 1)
      )
    );
  }, [
    voteTime,
    onlinePlayers,
    tableAnswers,
    voteTimeLimit,
    votingStarted,
    user?.id,
  ]);

  useEffect(() => {
    if (game) {
      const { hand, table } = game;
      setPlayers((p) =>
        JSON.stringify(p) !== JSON.stringify(game.players) ? game.players : p,
      );
      setHandCards((h) =>
        JSON.stringify(h) !== JSON.stringify(hand) ? (hand as any) : h,
      );

      table.answers.sort(({ user_id }) => (user?.id === user_id ? -1 : 0));

      setTableAnswers((t) =>
        JSON.stringify(t) !== JSON.stringify(table.answers)
          ? table.answers.map((answer) => ({
              ...answer,
              user: game.players.find(
                ({ id }) => id === answer.user_id,
              ) as User,
            }))
          : t,
      );

      setQuestionCard((q) =>
        JSON.stringify(q) !== JSON.stringify(table.question)
          ? (table.question as any)
          : q,
      );
    }
  }, [game, getSession, nextQuestion, user?.id, votingStarted]);

  useEffect(() => {
    if (votingStarted || (answerTime && answerTimeLimit <= 0)) {
      setShowHand(false);
    }
  }, [answerTimeLimit, answerTime, votingStarted]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (votingStarted) {
        const settingsTime = voteTime;
        const updated = voteStarted;

        if (!settingsTime || !updated) {
          setShowTime(false);
        } else {
          const time =
            new Date(updated).getTime() + settingsTime * MINUTES - Date.now();
          setVoteTimeLimit(time);
          setShowTime(true);
          setAnswerTimeLimit(answerTime || 0);
        }
      } else {
        const settingsTime = answerTime;
        const updated = answerStarted;

        if (!settingsTime || !updated) {
          setShowTime(false);
        } else {
          setVoteTimeLimit(voteTime || 0);
          const time =
            new Date(updated).getTime() + settingsTime * MINUTES - Date.now();
          setAnswerTimeLimit(time);
          setShowTime(true);
        }
      }
    }, 1000);

    if (votingFinished) {
      setShowTime(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [
    answerStarted,
    voteStarted,
    votingStarted,
    votingFinished,
    voteTime,
    answerTime,
  ]);

  return (
    <GameContext.Provider
      value={{
        questionCard,
        handCards,
        showHand,
        selectedCards,
        setQuestionCard,
        setHandCards,
        setShowHand,
        setSelectedCards,
        code,
        getGameState,
        players,
        tableAnswers,
        setTableAnswers,
        votingStarted,
        votingFinished,
        adminId: game?.game?.user_id,
        answerTimeLimit,
        voteTimeLimit,
        settings: game?.game?.settings,
        voteStartedTime: voteStarted,
        answerStartedTime: answerStarted,
        showTime,
        finished: game?.game?.finished,
        chatMessages,
        setChatMessages,
        onlinePlayers,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

export const useGameContext = () => {
  const context = useContext(GameContext);

  if (!context) {
    throw new Error("useGameContext needs to be under a GameContextProvider");
  }

  return context;
};
