export enum CARD_TYPE {
  ANSWER = "answer",
  QUESTION = "question",
}

export type GameCard = {
  id: string;
  text: string;
  type: CARD_TYPE;
  answers?: number;
};
