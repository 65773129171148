import { Card, CardActions, CardContent } from "@mui/material";
import styled from "styled-components";

export const CardListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1rem;

  @media screen and (max-width: 1260px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 670px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  direction: row;
  justify-content: space-between;
  gap: 1rem;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
`;

export const StyledCardContent = styled(CardContent)`
  flex: 1;
`;

export const StyledCardActions = styled(CardActions)`
  flex-wrap: wrap;
  gap: 0.5rem;

  > * {
    margin-left: 0;
  }
`;
