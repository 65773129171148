import styled from "styled-components";

export const BoardContainer = styled.div<{
  $open?: boolean;
  $finished?: boolean;
}>`
  position: fixed;
  z-index: ${({ $open }) => ($open ? 2 : 1)};
  top: ${({ $finished }) => ($finished ? "20px" : "5px")};
  left: ${({ $finished }) => ($finished ? "20px" : "5px")};
  bottom: ${({ $finished }) => ($finished ? "20px" : "unset")};
  right: ${({ $finished }) => ($finished ? "20px" : "unset")};
  padding: ${({ $open }) => ($open ? "0.25rem 0.5rem" : "0")};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: ${({ $open }) => ($open ? "3px 3px 10px 0px #0000004d" : "none")};
  background: ${({ $open }) => ($open ? "white" : "none")};
  width: ${({ $open, $finished }) =>
    $finished ? "unset" : $open ? "250px" : "50px"};
  transition: 200ms cubic-bezier(0.46, -0.39, 0.57, 1.32);

  display: flex;
  flex-direction: column;

  ul {
    transition: 100ms cubic-bezier(0.46, -0.39, 0.57, 1.32);
    list-style: none;
    padding: 0;
    margin: 0;
    opacity: ${({ $open }) => ($open ? 1 : 0)};
    max-height: ${({ $open }) => ($open ? "90vh" : "0px")};
    margin-top: ${({ $finished }) => ($finished ? "1rem" : "unset")};

    li {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
`;

export const BoardHeader = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;

  button {
    background: none;
    border: none;
    outline: none;
    font-size: 16px;
    height: 40px;
    width: 40px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 0.25rem;
    color: black;
    -webkit-tap-highlight-color: transparent;
  }
`;
