import { useCallback, useState } from "react";
import { API_ROUTES } from "../../api/routes";
import { GameClient } from "../../api/GameClient";
import { GameState, SettingsData } from "../../types/GameState";
import { AxiosError } from "axios";
import { toast } from "material-react-toastify";
import { User } from "../../types/Session";

export const useUpdateGameSettings = () => {
  const [loading, setLoading] = useState(false);
  const [game, setGame] = useState<GameState>();
  const [error, setError] = useState<AxiosError>();

  const updateGameSettings = useCallback(
    async (
      code: string,
      user: Partial<User>,
      settings: Partial<SettingsData>
    ) => {
      setError(undefined);
      setLoading(true);
      try {
        const { data } = await GameClient.put(API_ROUTES.SETTINGS(code), {
          ...user,
          settings,
        });
        toast.success("Configurações alteradas");
        if (data) {
          setGame(data);
          setLoading(false);
          return data;
        }
      } catch (e) {
        setLoading(false);
        const error = e as AxiosError<any, any>;
        error.message = error.response?.data?.message || error.message;
        setError(e as any);
      }
    },
    []
  );

  return { game, updateGameSettings, loading, error };
};
