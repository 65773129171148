import styled from "styled-components";

export const GameContainer = styled.div`
  width: 100%;
  min-height: 90vh;
  min-height: -webkit-fill-available;
  background: ${({ theme }) => theme.appBackground};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: gradientAnimation 12s ease infinite;
  background-size: 400% 400%;

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
