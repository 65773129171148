import { useCallback, useState } from "react";
import { API_ROUTES } from "../../api/routes";
import { GameClient } from "../../api/GameClient";
import { AxiosError } from "axios";

export const useVoteAnswer = (code: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const [complete, setComplete] = useState(false);

  const voteAnswer = useCallback(
    async (user_id: string) => {
      setComplete(false);
      setError(undefined);
      setLoading(true);
      try {
        const { data } = await GameClient.post(API_ROUTES.VOTE(code), {
          user_id,
        });
        if (data) {
          setComplete(true);
          setLoading(false);
          return data;
        }
      } catch (e) {
        setLoading(false);
        const error = e as AxiosError<any, any>;
        error.message = error.response?.data?.message || error.message;
        setError(e as any);
      }
    },
    [code]
  );

  return { voteAnswer, loading, error, complete };
};
