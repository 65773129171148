export enum AnimalsEnum {
  alligator = "alligator",
  anteater = "anteater",
  armadillo = "armadillo",
  auroch = "auroch",
  axolotl = "axolotl",
  badger = "badger",
  bat = "bat",
  beaver = "beaver",
  buffalo = "buffalo",
  camel = "camel",
  capybara = "capybara",
  chameleon = "chameleon",
  cheetah = "cheetah",
  chinchilla = "chinchilla",
  chipmunk = "chipmunk",
  chupacabra = "chupacabra",
  cormorant = "cormorant",
  coyote = "coyote",
  crow = "crow",
  dingo = "dingo",
  dinosaur = "dinosaur",
  dolphin = "dolphin",
  duck = "duck",
  elephant = "elephant",
  ferret = "ferret",
  fox = "fox",
  frog = "frog",
  giraffe = "giraffe",
  gopher = "gopher",
  grizzly = "grizzly",
  hedgehog = "hedgehog",
  hippo = "hippo",
  hyena = "hyena",
  ibex = "ibex",
  ifrit = "ifrit",
  iguana = "iguana",
  jackal = "jackal",
  kangaroo = "kangaroo",
  koala = "koala",
  kraken = "kraken",
  lemur = "lemur",
  leopard = "leopard",
  liger = "liger",
  llama = "llama",
  manatee = "manatee",
  mink = "mink",
  monkey = "monkey",
  moose = "moose",
  narwhal = "narwhal",
  orangutan = "orangutan",
  otter = "otter",
  panda = "panda",
  penguin = "penguin",
  platypus = "platypus",
  pumpkin = "pumpkin",
  python = "python",
  quagga = "quagga",
  rabbit = "rabbit",
  raccoon = "raccoon",
  rhino = "rhino",
  sheep = "sheep",
  shrew = "shrew",
  skunk = "skunk",
  squirrel = "squirrel",
  tiger = "tiger",
  turtle = "turtle",
  walrus = "walrus",
  wolf = "wolf",
  wolverine = "wolverine",
  wombat = "wombat",
}

export const AnimalNamesPtBr = {
  alligator: "Jacaré",
  anteater: "Tamanduá",
  armadillo: "Tatu",
  auroch: "Auroque",
  axolotl: "Axolote",
  badger: "Texugo",
  bat: "Morcego",
  beaver: "Castor",
  buffalo: "Búfalo",
  camel: "Camelo",
  capybara: "Capivara",
  chameleon: "Camaleão",
  cheetah: "Guepardo",
  chinchilla: "Chinchila",
  chipmunk: "Esquilo",
  chupacabra: "Chupacabra",
  cormorant: "Cormorão",
  coyote: "Coiote",
  crow: "Corvo",
  dingo: "Dingo",
  dinosaur: "Dinossauro",
  dolphin: "Golfinho",
  duck: "Pato",
  elephant: "Elefante",
  ferret: "Furão",
  fox: "Raposa",
  frog: "Sapo",
  giraffe: "Girafa",
  gopher: "Esquilo",
  grizzly: "Urso",
  hedgehog: "Ouriço",
  hippo: "Hipopótamo",
  hyena: "Hiena",
  ibex: "Íbex",
  ifrit: "Ifrit",
  iguana: "Iguana",
  jackal: "Chacal",
  kangaroo: "Canguru",
  koala: "Coala",
  kraken: "Kraken",
  lemur: "Lêmure",
  leopard: "Leopardo",
  liger: "Ligre",
  llama: "Lhama",
  manatee: "Peixe-boi",
  mink: "Vison",
  monkey: "Macaco",
  moose: "Alce",
  narwhal: "Narval",
  orangutan: "Orangotango",
  otter: "Lontra",
  panda: "Panda",
  penguin: "Pinguim",
  platypus: "Ornitorrinco",
  pumpkin: "Abóbora",
  python: "Píton",
  quagga: "Quagga",
  rabbit: "Coelho",
  raccoon: "Guaxinim",
  rhino: "Rinoceronte",
  sheep: "Ovelha",
  shrew: "Musaranho",
  skunk: "Gambá",
  squirrel: "Esquilo",
  tiger: "Tigre",
  turtle: "Tartaruga",
  walrus: "Morsa",
  wolf: "Lobo",
  wolverine: "Carcaju",
  wombat: "Vombate",
};
