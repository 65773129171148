import { Fab, Zoom } from "@mui/material";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import { useGameContext } from "./GameContext";

type SoundContextProps = {
  playCardAudio: () => void,
  playBubbleAudio: () => void,
  playClickAudio: () => void,
  playPopAudio: () => void,
  playClockAudio: () => void,
  playPositiveAudio: () => void,
  playWinAudio: () => void,
  playBellAudio: () => void,
  stopClockAudio: () => void,
};

const SoundContext = createContext<SoundContextProps>({
  playCardAudio: () => null,
  playBubbleAudio: () => null,
  playClickAudio: () => null,
  playPopAudio: () => null,
  playClockAudio: () => null,
  playPositiveAudio: () => null,
  playWinAudio: () => null,
  playBellAudio: () => null,
  stopClockAudio: () => null,
});

export const SoundContextProvider: React.FC<{
  children: Array<React.ReactNode> | React.ReactNode,
}> = ({ children }) => {
  const [enabled, setEnabled] = useState<boolean>();
  const { finished } = useGameContext();

  const cardAudio = useMemo(() => new Audio("/sound/card.mp3"), []);
  const bubbleAudio = useMemo(() => new Audio("/sound/bubble.mp3"), []);
  const clickAudio = useMemo(() => new Audio("/sound/click.mp3"), []);
  const popAudio = useMemo(() => new Audio("/sound/pop.mp3"), []);
  const clockAudio = useMemo(() => new Audio("/sound/clock.mp3"), []);
  const positiveAudio = useMemo(() => new Audio("/sound/positive.mp3"), []);
  const winAudio = useMemo(() => new Audio("/sound/win.mp3"), []);
  const bellAudio = useMemo(() => new Audio("/sound/bell.mp3"), []);

  useEffect(() => {
    setEnabled(!!+(localStorage.getItem("sound") ?? 1));
  }, []);

  useEffect(() => {
    if (typeof enabled !== "undefined") {
      localStorage.setItem("sound", enabled ? "1" : "0");
    }
  }, [enabled]);

  const playAudio = useCallback((audio: HTMLAudioElement) =>
    setEnabled(e => {
      if (e) {
        audio.play().catch((error) => console.error("Cannot play audio", error));
      }
      return e;
    }),
    []
  );

  const playCardAudio = useCallback(() => playAudio(cardAudio), [cardAudio, playAudio]);
  const playBubbleAudio = useCallback(() => playAudio(bubbleAudio), [bubbleAudio, playAudio]);
  const playClickAudio = useCallback(() => playAudio(clickAudio), [clickAudio, playAudio]);
  const playPopAudio = useCallback(() => playAudio(popAudio), [playAudio, popAudio]);
  const playClockAudio = useCallback(() => playAudio(clockAudio), [clockAudio, playAudio]);
  const playPositiveAudio = useCallback(() => playAudio(positiveAudio), [playAudio, positiveAudio]);
  const playWinAudio = useCallback(() => playAudio(winAudio), [playAudio, winAudio]);
  const playBellAudio = useCallback(() => playAudio(bellAudio), [playAudio, bellAudio]);

  const stopClockAudio = useCallback(() => {
    clockAudio.pause();
    clockAudio.currentTime = 0;
  }, [clockAudio]);

  return (
    <SoundContext.Provider value={{
      playCardAudio,
      playBubbleAudio,
      playClickAudio,
      playPopAudio,
      playClockAudio,
      playPositiveAudio,
      playWinAudio,
      stopClockAudio,
      playBellAudio,
    }}>
      {children}

      <Zoom
        in={!finished}
        timeout={100}
        unmountOnExit
        style={{
          transitionDelay: "100ms",
          position: 'fixed',
          top: 125,
          right: 5,
          zIndex: 1,
        }}
      >
        <Fab
          color={enabled ? "info" : "inherit"}
          aria-label="add"
          onClick={() => setEnabled(e => !e)}
          size="medium"
        >
          {enabled ? (
            <FaVolumeUp size={18} />
          ) : (
            <FaVolumeMute size={18} />
          )}
        </Fab>
      </Zoom>
    </SoundContext.Provider>
  );
}

export const useSoundContext = () => {
  const context = useContext(SoundContext);

  if (!context) {
    throw new Error("useSoundContext needs to be under a SoundContextProvider");
  }

  return context;
}
