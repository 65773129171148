import styled from "styled-components";

export const PlayerIconSelectorContainer = styled.fieldset`
  display: flex;
  gap: 0.5rem;
  border-radius: 4;
  border: 1px solid lightgray;
  align-items: center;

  div.MuiInput-underline::after,
  div.MuiInput-underline::before {
    display: none;
  }

  input[type="color"] {
    border-radius: 5px;
    border: none;
  }
`;
