import Hand from "../Hand";
import { GameContainer } from "./Game.styles";
import Table from "../Table";
import { GameContextProvider } from "../../contexts/GameContext";
import Board from "../Board/Board";
import Chat from "../Chat/Chat";
import FinishGame from "../FinishGame/FinishGame";
import SettingsButton from "../SettingsButton/SettingsButton";
import { SoundContextProvider } from "../../contexts/SoundContext";

const Game = () => {
  return (
    <GameContextProvider>
      <SoundContextProvider>
        <GameContainer>
          <Board />
          <Table />
          <Hand />
          <Chat />
          <FinishGame />
          <SettingsButton />
        </GameContainer>
      </SoundContextProvider>
    </GameContextProvider>
  );
};

export default Game;
