import { createTheme } from "@mui/material/styles";

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#0066CC",
      light: "#66A3FF",
      dark: "#004C99",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#008080",
      light: "#008080",
      dark: "#FFD700",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#F1F4F9",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#212121",
      secondary: "#757575",
    },
    success: {
      main: "#28A745",
    },
    error: {
      main: "#DC3545",
    },
    warning: {
      main: "#FFC107",
    },
    info: {
      main: "#17A2B8",
    },
  },
  shape: {
    borderRadius: 12,
  },
  typography: {
    fontFamily: '"Raleway", sans-serif',
    h1: {
      fontFamily: '"Luckiest Guy", cursive',
      fontSize: "2rem",
      fontWeight: 700,
    },
    h2: {
      fontFamily: '"Luckiest Guy", cursive',
      fontSize: "1.75rem",
      fontWeight: 600,
    },
    h3: {
      fontFamily: '"Luckiest Guy", cursive',
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    body1: {
      fontFamily: '"Raleway", sans-serif',
      fontSize: "1rem",
    },
    body2: {
      fontFamily: '"Raleway", sans-serif',
      fontSize: "0.875rem",
    },
    caption: {
      fontFamily: '"Raleway", sans-serif',
      fontSize: "0.75rem",
    },
    button: {
      fontFamily: '"Roboto", sans-serif',
    },
    subtitle1: {
      fontFamily: '"Luckiest Guy", sans-serif',
      fontSize: "1.125rem",
    },
    subtitle2: {
      fontFamily: '"Raleway", sans-serif',
      fontSize: "0.875rem",
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: '"Roboto", sans-serif',
        },
      },
    },
  },
});
