import styled from "styled-components";

export const GameSettingsModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  max-width: 92vw;
  width: 700px;
`;

export const GameSettingsModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;

  button {
    min-width: unset;
  }
`;

export const GameSettingsModalFormContainer = styled.div`
  max-height: 65vh;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
`;

export const OverflowShadow = styled.div<{ $top?: boolean; $show?: boolean }>`
  width: 100%;
  height: 10px;
  scale: 1 ${({ $show }) => ($show ? 1 : 0)};
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  margin-top: ${({ $top }) => ($top ? "unset" : "-10px")};
  margin-bottom: ${({ $top }) => ($top ? "-10px" : "unset")};
  transition: 200ms;
  background: linear-gradient(
    ${({ $top }) => ($top ? 180 : 0)}deg,
    #9696968c,
    transparent
  );
`;

export const GameSettingsModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const DecksColumnsContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  > div {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        label {
          margin: 0;
        }
      }
    }

    flex: 1;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
