import { useCallback, useEffect, useRef, useState } from "react";
import { API_ROUTES } from "../../api/routes";
import { GameClient } from "../../api/GameClient";
import { UserGameState } from "../../types/GameState";
import { AxiosError } from "axios";
import { GameCard } from "../../types/Card";
import { toast } from "material-react-toastify";
import { ChatMessage } from "../../types/ChatMessage";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes";
import useWebSocket from "react-use-websocket";
import { User } from "../../types/Session";

export const useGameState = (code: string) => {
  const [loading, setLoading] = useState(false);
  const [game, setGame] = useState<UserGameState>();
  const [error, setError] = useState<AxiosError>();

  const toastRef = useRef<any>();

  const navigate = useNavigate();

  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);

  const getGameFromSession = useCallback(() => {
    const gameObject = JSON.parse(
      sessionStorage.getItem(`game-${code}`) || "null",
    );
    if (gameObject) {
      setGame(gameObject);
    }
    const chat = JSON.parse(sessionStorage.getItem(`chat-${code}`) || "[]");
    if (chat?.length) {
      setChatMessages(chat);
    }
  }, [code]);

  useEffect(() => {
    if (game) {
      sessionStorage.setItem(`game-${code}`, JSON.stringify(game));
    }
  }, [code, game]);

  useEffect(() => {
    if (chatMessages?.length) {
      sessionStorage.setItem(`chat-${code}`, JSON.stringify(chatMessages));
    }
  }, [code, chatMessages]);

  const getGameState = useCallback(
    async (code: string) => {
      setError(undefined);
      if (!code) {
        return;
      }
      setLoading(true);
      try {
        const { data } = await GameClient.get(API_ROUTES.GAME(code));
        if (data) {
          setGame((g) =>
            JSON.stringify(g) !== JSON.stringify(data)
              ? {
                  ...g,
                  ...data,
                }
              : g,
          );
          setLoading(false);
          return data;
        }
      } catch (e) {
        setLoading(false);
        const error = e as AxiosError<any, any>;
        error.message = error.response?.data?.message || error.message;
        setError(e as any);
        toastRef.current = toast.error("Partida não encontrada.");
        navigate(PATHS.GAME.HOME());
      }
    },
    [navigate],
  );

  const onSettingsEvent = (settings: any) => {
    setGame(
      (g) =>
        g && {
          ...g,
          game: {
            ...g.game,
            settings: {
              ...g.game.settings,
              ...settings,
            },
          },
        },
    );
  };

  const onTableEvent = (table: any) => {
    table.answers = table.answers.filter((ans: any) =>
      ans.cards.every((card: any) => !!card),
    );

    setGame(
      (g) =>
        g && {
          ...g,
          table: {
            ...g.table,
            ...table,
          },
        },
    );
  };

  const onPlayersEvent = (players: Array<Partial<User>>) => {
    setGame(
      (g) =>
        g && {
          ...g,
          players,
        },
    );
  };

  const onHandEvent = (data: GameCard[]) => {
    if (data.length) {
      setGame(
        (g) =>
          g && {
            ...g,
            hand: data,
          },
      );
    }
  };

  const onFinish = () => {
    toast.success("Fim de jogo!");
    setGame(
      (g) =>
        g && {
          ...g,
          game: {
            ...g.game,
            finished: true,
          },
        },
    );
  };

  useWebSocket(`${process.env.REACT_APP_API_URL}/game/${code}/connect`, {
    onOpen: () => console.log("Websocket connected"),
    shouldReconnect: () => true,
    reconnectAttempts: 20,
    reconnectInterval: 2000,
    retryOnError: true,
    onError: console.log,
    onClose: () => console.log("Websocket closed"),
    share: true,
    onReconnectStop: () => {
      toastRef.current = toast.error("Desconectado da partida.");
      window.location.href = PATHS.GAME.HOME();
    },
    heartbeat: {
      interval: 10000,
      message: "ping",
      returnMessage: "pong",
      timeout: 20000,
    },
    onMessage: (message) => {
      try {
        const { type, data } = JSON.parse(message.data);
        switch (type) {
          case "connected":
            getGameState(code);
            break;
          case "finish":
            onFinish();
            break;
          case "hand":
            onHandEvent(data);
            break;
          case "chat":
            setChatMessages((c) => [...c, data]);
            break;
          case "update":
            getGameState(code);
            break;
          case "settings":
            onSettingsEvent(data);
            break;
          case "table":
            onTableEvent(data);
            break;
          case "players":
            onPlayersEvent(data);
            break;
          default:
            break;
        }
      } catch {
        console.log("[WS]", message.type, message.data);
      }
    },
  });

  useEffect(() => {
    getGameFromSession();
    getGameState(code);
  }, [getGameFromSession, getGameState, code]);

  return {
    game,
    getGameState,
    loading,
    error,
    chatMessages,
    setChatMessages,
  };
};
