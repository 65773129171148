import { useCallback, useEffect, useState } from "react";
import { DeckData } from "../../../types/Deck";
import { CrudClient } from "../../../api/CrudClient";
import { API_ROUTES } from "../../../api/routes";

export const useListDecks = (skip?: boolean) => {
  const [loading, setLoading] = useState(false);
  const [decks, setDecks] = useState<DeckData[]>();

  const getDecks = useCallback(async () => {
    setLoading(true);
    const { data } = await CrudClient.get(API_ROUTES.DECKS());
    setDecks(data || []);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!skip) getDecks();
  }, [getDecks, skip]);

  return { decks, getDecks, loading };
};
