import axios from "axios";

export const CrudClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "/api",
  withCredentials: true,
});

CrudClient.interceptors.request.use((request) => {
  request.headers.set("password", localStorage.getItem("client-password"));
  return request;
});

CrudClient.interceptors.response.use(
  (response) => response,
  ({ response }) => {
    if (response.status === 401 && !(window as any).promptingPassword) {
      (window as any).promptingPassword = true;
      localStorage.setItem("client-password", prompt("Senha") || "");
      window.location.reload();
    }
    response.status = 200;
    return response;
  },
);
