import {
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  DialogActions,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  DecksColumnsContainer,
  GameSettingsModalContainer,
  GameSettingsModalForm,
  GameSettingsModalFormContainer,
  GameSettingsModalHeader,
  OverflowShadow,
} from "./GameSettingsModal.styles";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useNewGame } from "../../hooks/Game/useNewGame";
import { PATHS } from "../../routes";
import { SettingsData } from "../../types/GameState";
import { useListDecks } from "../../hooks/CRUD/deck/useListDecks";
import { useListCards } from "../../hooks/CRUD/card/useListCards";
import { CARD_TYPE } from "../../types/Card";
import { useUpdateGameSettings } from "../../hooks/Game/useUpdateGameSettings";
import PlayerIconSelector from "../PlayerIconSelector/PlayerIconSelector";

type GameSettingsModalProps = {
  visible?: boolean;
  onClose?: () => void;
  code?: string;
  settings?: SettingsData;
  isAdmin?: boolean;
};

const GameSettingsModal: React.FC<GameSettingsModalProps> = ({
  visible = false,
  onClose,
  code,
  settings,
  isAdmin,
}) => {
  const [name, setName] = useState("");
  const [maxPlayers, setMaxPlayers] = useState(10);
  const [voteTime, setVoteTime] = useState(0);
  const [answerTime, setAnswerTime] = useState(0);
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState({ color: "", animal: "" });
  const [selectedAnswerDecks, setSelectedAnswerDecks] = useState<{
    [deckId: string]: boolean;
  }>({});
  const [selectedQuestionDecks, setSelectedQuestionDecks] = useState<{
    [deckId: string]: boolean;
  }>({});
  const [answerDeckMap, setAnswerDeckMap] = useState<{
    [deckId: string]: number;
  }>({});
  const [questionDeckMap, setQuestionDeckMap] = useState<{
    [deckId: string]: number;
  }>({});
  const [showBottomShadow, setShowBottomShadow] = useState(false);
  const [showTopShadow, setShowTopShadow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const isEditing = !!code;

  const { decks } = useListDecks(isEditing);
  const { cards: allQuestionCards } = useListCards(
    CARD_TYPE.QUESTION,
    isEditing,
  );
  const { cards: allAnswerCards } = useListCards(CARD_TYPE.ANSWER, isEditing);

  const { loading: newLoading, error: newError, newGame } = useNewGame();
  const {
    loading: updateLoading,
    error: updateError,
    updateGameSettings,
  } = useUpdateGameSettings();

  const loading = newLoading || updateLoading;
  const error = newError || updateError;

  const totalQuestionCardsCount = useMemo(
    () =>
      allQuestionCards?.filter(({ id: cardId }) =>
        decks?.find(
          ({ id: deckId, cards }) =>
            selectedQuestionDecks[deckId] && cards.includes(cardId),
        ),
      ).length || 0,
    [allQuestionCards, decks, selectedQuestionDecks],
  );

  const totalAnswerCardsCount = useMemo(
    () =>
      allAnswerCards?.filter(({ id: cardId }) =>
        decks?.find(
          ({ id: deckId, cards }) =>
            selectedAnswerDecks[deckId] && cards.includes(cardId),
        ),
      ).length || 0,
    [allAnswerCards, decks, selectedAnswerDecks],
  );

  const valid = useMemo(
    () =>
      name?.length > 3 &&
      (isEditing ||
        (totalQuestionCardsCount >= 10 && totalAnswerCardsCount >= 50)) &&
      maxPlayers >= 1 &&
      maxPlayers <= 15 &&
      (!password || password.length > 3),
    [
      name?.length,
      isEditing,
      totalQuestionCardsCount,
      totalAnswerCardsCount,
      maxPlayers,
      password,
    ],
  );

  useEffect(() => {
    const answers: any =
      allAnswerCards?.reduce(
        (mem, card) => ({
          ...mem,
          [card.id]: true,
        }),
        {},
      ) || {};
    const questions: any =
      allQuestionCards?.reduce(
        (mem, card) => ({
          ...mem,
          [card.id]: true,
        }),
        {},
      ) || {};

    setAnswerDeckMap(
      (decks || []).reduce(
        (mem, { id, cards }) => ({
          ...mem,
          [id]: cards.filter((card) => answers[card]).length,
        }),
        {},
      ),
    );
    setQuestionDeckMap(
      (decks || []).reduce(
        (mem, { id, cards }) => ({
          ...mem,
          [id]: cards.filter((card) => questions[card]).length,
        }),
        {},
      ),
    );
  }, [allAnswerCards, allQuestionCards, decks]);

  const resetDecks = useCallback(() => {
    setSelectedAnswerDecks(
      (decks || []).reduce(
        (mem, { id }) => ({
          ...mem,
          [id]: true,
        }),
        {},
      ),
    );
    setSelectedQuestionDecks(
      (decks || []).reduce(
        (mem, { id }) => ({
          ...mem,
          [id]: true,
        }),
        {},
      ),
    );
  }, [decks]);

  const reset = useCallback(() => {
    setName(localStorage.getItem("name") || "");
    if (settings) {
      setMaxPlayers(settings.maxPlayers);
      setVoteTime(settings.voteTime);
      setAnswerTime(settings.answerTime);
      setPassword(settings.password);
    } else {
      setMaxPlayers(10);
      setVoteTime(0);
      setAnswerTime(0);
      setPassword("");
      resetDecks();
    }
  }, [resetDecks, settings]);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (visible) {
      setTimeout(
        () =>
          containerRef.current &&
          setShowBottomShadow(
            containerRef.current.scrollHeight - containerRef.current.scrollTop >
              containerRef.current.clientHeight + 1,
          ),
        500,
      );
    }
  }, [visible]);

  useEffect(() => {
    if (name?.length > 3) {
      localStorage.setItem("name", name);
    }
  }, [name]);

  const handleClose = () => {
    reset();
    onClose?.();
  };

  const handleSubmit = async () => {
    if (!valid) return;

    if (isEditing) {
      await updateGameSettings(
        code,
        {
          name,
          avatar: avatar.animal,
          color: avatar.color,
        },
        {
          answerTime,
          maxPlayers,
          password,
          voteTime,
        },
      );
      onClose?.();
    } else {
      const game = await newGame(
        {
          name,
          avatar: avatar.animal,
          color: avatar.color,
        },
        {
          answerTime,
          maxPlayers,
          password,
          voteTime,
          answerDecks: Object.keys(selectedAnswerDecks),
          questionDecks: Object.keys(selectedQuestionDecks),
        },
      );
      if (game?.game?.code) {
        window.location.assign(PATHS.GAME.PLAY(game.game.code));
      }
    }
  };

  return (
    <Modal open={visible} onClose={handleClose}>
      <Zoom
        in={visible}
        timeout={100}
        style={{
          transitionDelay: "100ms",
        }}
      >
        <GameSettingsModalContainer>
          <Card>
            <CardContent>
              <GameSettingsModalHeader>
                <Typography variant="h4">
                  {isEditing ? "Configurações" : "Novo jogo"}
                </Typography>
                <Button variant="text" color="inherit" onClick={onClose}>
                  <FaTimes size={20} />
                </Button>
              </GameSettingsModalHeader>

              <OverflowShadow $top $show={showTopShadow} />
              <GameSettingsModalFormContainer
                ref={containerRef}
                onScroll={({ target }: any) => {
                  setShowTopShadow(target.scrollTop > 1);
                  setShowBottomShadow(
                    target.scrollHeight - target.scrollTop >
                      target.clientHeight + 1,
                  );
                }}
              >
                <GameSettingsModalForm>
                  {!!error?.message && (
                    <Alert color="error">{error.message}</Alert>
                  )}

                  <TextField
                    label="Seu nome"
                    variant="outlined"
                    value={name}
                    fullWidth
                    error={name.length < 4}
                    onChange={(event) => setName(event.target.value)}
                    required
                  />

                  <PlayerIconSelector onChange={setAvatar} />

                  {(isAdmin || !isEditing) && (
                    <>
                      <TextField
                        label="Máximo de jogadores na sala"
                        variant="outlined"
                        value={maxPlayers}
                        fullWidth
                        datatype="number"
                        type="number"
                        error={maxPlayers < 1 || maxPlayers > 15}
                        onChange={(event) => setMaxPlayers(+event.target.value)}
                        required
                      />

                      <FormControl fullWidth required>
                        <InputLabel id="answers-label" required>
                          Tempo para escolher as respostas
                        </InputLabel>
                        <Select
                          label="Tempo para escolher as respostas"
                          value={answerTime}
                          onChange={(event) =>
                            setAnswerTime(+event.target.value)
                          }
                          labelId="answers-label"
                          disabled={loading}
                          variant="outlined"
                          required
                        >
                          <MenuItem value={0}>Ilimitado</MenuItem>
                          <MenuItem value={0.5}>30 segundos</MenuItem>
                          {new Array(5).fill(null).map((_, i) => (
                            <MenuItem key={i} value={i + 1}>
                              {i + 1} minutos
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl fullWidth required>
                        <InputLabel id="answers-label" required>
                          Tempo para votar na melhor resposta
                        </InputLabel>
                        <Select
                          label="Tempo para votar na melhor resposta"
                          value={voteTime}
                          onChange={(event) => setVoteTime(+event.target.value)}
                          labelId="answers-label"
                          disabled={loading}
                          variant="outlined"
                          required
                        >
                          <MenuItem value={0}>Ilimitado</MenuItem>
                          <MenuItem value={0.5}>30 segundos</MenuItem>
                          {new Array(5).fill(null).map((_, i) => (
                            <MenuItem key={i} value={i + 1}>
                              {i + 1} minutos
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <TextField
                        label="Senha (vazio = sem senha)"
                        variant="outlined"
                        value={password}
                        fullWidth
                        error={!!password.length && password.length < 4}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </>
                  )}

                  {!isEditing && (
                    <>
                      <Typography variant="h5">
                        Selecione os baralhos
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              Object.keys(questionDeckMap).length ===
                                Object.keys(selectedQuestionDecks).length &&
                              Object.keys(answerDeckMap).length ===
                                Object.keys(selectedAnswerDecks).length
                            }
                            onChange={({ target }) => {
                              if (target.checked) {
                                resetDecks();
                              } else {
                                setSelectedQuestionDecks({});
                                setSelectedAnswerDecks({});
                              }
                            }}
                          />
                        }
                        label="Todos"
                      />
                      <DecksColumnsContainer>
                        <div>
                          <Typography
                            variant="h6"
                            color={
                              totalQuestionCardsCount < 10
                                ? "error"
                                : "textPrimary"
                            }
                          >
                            Perguntas ({totalQuestionCardsCount} cartas)
                          </Typography>
                          <ul>
                            {decks?.map(
                              ({ id, name, description }) =>
                                !!questionDeckMap[id] && (
                                  <li key={id}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={!!selectedQuestionDecks[id]}
                                          onChange={() =>
                                            setSelectedQuestionDecks(
                                              (selected) =>
                                                JSON.parse(
                                                  JSON.stringify({
                                                    ...selected,
                                                    [id]: selected[id]
                                                      ? undefined
                                                      : true,
                                                  }),
                                                ),
                                            )
                                          }
                                        />
                                      }
                                      label={
                                        <>
                                          <Typography
                                            lineHeight="1rem"
                                            marginTop="0.5rem"
                                          >
                                            <b>{name}</b> ({questionDeckMap[id]}{" "}
                                            cartas)
                                          </Typography>
                                          <Typography
                                            lineHeight="1rem"
                                            variant="body2"
                                            marginBottom="0.25rem"
                                          >
                                            {description}
                                          </Typography>
                                        </>
                                      }
                                    />
                                  </li>
                                ),
                            )}
                          </ul>
                        </div>
                        <div>
                          <Typography
                            variant="h6"
                            color={
                              totalAnswerCardsCount < 50
                                ? "error"
                                : "textPrimary"
                            }
                          >
                            Respostas ({totalAnswerCardsCount} cartas)
                          </Typography>
                          <ul>
                            {decks?.map(
                              ({ id, name, description }) =>
                                !!answerDeckMap[id] && (
                                  <li key={id}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={!!selectedAnswerDecks[id]}
                                          onChange={() =>
                                            setSelectedAnswerDecks((selected) =>
                                              JSON.parse(
                                                JSON.stringify({
                                                  ...selected,
                                                  [id]: selected[id]
                                                    ? undefined
                                                    : true,
                                                }),
                                              ),
                                            )
                                          }
                                        />
                                      }
                                      label={
                                        <>
                                          <Typography
                                            lineHeight="1rem"
                                            marginTop="0.5rem"
                                          >
                                            <b>{name}</b> ({answerDeckMap[id]}{" "}
                                            cartas)
                                          </Typography>
                                          <Typography
                                            lineHeight="1rem"
                                            variant="body2"
                                            marginBottom="0.25rem"
                                          >
                                            {description}
                                          </Typography>
                                        </>
                                      }
                                    />
                                  </li>
                                ),
                            )}
                          </ul>
                        </div>
                      </DecksColumnsContainer>
                    </>
                  )}
                </GameSettingsModalForm>
              </GameSettingsModalFormContainer>
              <OverflowShadow $show={showBottomShadow} />
            </CardContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="outlined"
                startIcon={<FaTimes />}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : (
                    <FaCheck />
                  )
                }
                disabled={!valid}
              >
                {isEditing ? "Salvar" : "Criar"}
              </Button>
            </DialogActions>
          </Card>
        </GameSettingsModalContainer>
      </Zoom>
    </Modal>
  );
};

export default GameSettingsModal;
