import { useCallback, useEffect, useState } from "react";
import { API_ROUTES } from "../../api/routes";
import { Session } from "../../types/Session";
import { ApiClient } from "../../api/ApiClient";

export const useSession = () => {
  const [session, setSesssion] = useState<Session>();

  const getSessionFromStorage = useCallback(() => {
    const gameObject = JSON.parse(sessionStorage.getItem("session") || "null");
    if (gameObject) {
      setSesssion(gameObject);
    }
  }, []);

  const getSession = useCallback(async () => {
    try {
      const { data } = await ApiClient.get(API_ROUTES.SESSION());
      if (data) {
        setSesssion((s) => {
          const newSession = {
            ...s,
            ...data,
          };
          sessionStorage.setItem("session", JSON.stringify(newSession));
          return newSession;
        });
        return data;
      }
    } catch {}
  }, []);

  useEffect(() => {
    getSessionFromStorage();
    getSession();
  }, [getSession, getSessionFromStorage]);

  return { session, getSession };
};
