import { Alert, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DeckModalContainer, DeckModalForm, DeckModalHeader } from './DeckModal.styles';
import { DeckData } from '../../../types/Deck';
import { FaSave, FaTimes, FaTrash } from 'react-icons/fa';
import { useCreateDeck } from '../../../hooks/CRUD/deck/useCreateDeck';
import { useUpdateDeck } from '../../../hooks/CRUD/deck/useUpdateDeck';
import { useDeleteDeck } from '../../../hooks/CRUD/deck/useDeleteDeck';

type DeckModalProps = {
  visible?: boolean;
  onClose?: () => void;
  deck?: Partial<DeckData>;
  onSave?: () => void;
}

const DeckModal: React.FC<DeckModalProps> = ({ visible = false, onClose, deck, onSave }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { loading: createLoading, error: createError, createDeck } = useCreateDeck();
  const { loading: updateLoading, error: updateError, updateDeck } = useUpdateDeck();
  const { loading: deleteLoading, deleteDeck } = useDeleteDeck();

  const loading = createLoading || updateLoading;
  const error = createError || updateError;

  useEffect(() => {
    if (deck) {
      setName(deck.name || "");
      setDescription(deck.description || "");
    }
  }, [deck]);

  const reset = () => {
    setName("");
    setDescription("");
  }

  const handleClose = () => {
    reset();
    onClose?.();
  }

  const handleSubmit = async () => {
    if (!name) return;
    let data;
    if (deck?.id) {
      data = await updateDeck(deck.id, {
        name,
        description,
      });
    } else {
      data = await createDeck({
        name,
        description,
      });
    }
    if (data?.id) {
      onSave?.();
      handleClose();
    }
  }

  const onConfirmDelete = async () => {
    if (deck?.id) {
      const data = await deleteDeck(deck.id);
      if (data) {
        setConfirmDelete(false);
        onSave?.();
        handleClose();
      }
    }
  }

  return (
    <>
      <Modal
        open={visible}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <DeckModalContainer>
          <Card>
            <CardContent>
              <DeckModalHeader>
                <Typography variant="h4">
                  {deck?.id ? "Editar" : "Criar"} carta
                </Typography>
                <Button variant="text" color="inherit" onClick={onClose}>
                  <FaTimes size={20} />
                </Button>
              </DeckModalHeader>

              <DeckModalForm>
                {!!error && (
                  <Alert color="error">
                    {error.message}
                  </Alert>
                )}

                <TextField
                  label="Nome"
                  variant="filled"
                  value={name}
                  fullWidth
                  onChange={(event) => setName(event.target.value.replace(/\n/g, ""))}
                />

                <TextField
                  label="Descrição"
                  variant="filled"
                  multiline
                  value={description}
                  fullWidth
                  onChange={(event) => setDescription(event.target.value.replace(/\n/g, ""))}
                  minRows={3}
                />
              </DeckModalForm>
            </CardContent>
            <DialogActions>
              {!!deck?.id && (
                <Button onClick={() => setConfirmDelete(true)} variant="contained" color="error" disabled={loading}>
                  Excluir
                </Button>
              )}
              <Button onClick={handleClose} variant="outlined" disabled={loading}>
                Cancelar
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                startIcon={loading ? <CircularProgress color="inherit" size={16} /> : <FaSave />}
                disabled={!name || !description}
              >
                Salvar
              </Button>
            </DialogActions>
          </Card>
        </DeckModalContainer>
      </Modal >

      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogTitle>Tem certeza que deseja excluir esse baralho?</DialogTitle>
        <DialogContent>
          <Card>
            <CardContent>
              <Typography marginTop="1rem" variant="body1">
                {name}
              </Typography>
              <Typography marginTop="1rem" variant="caption">
                {description}
              </Typography>
              <Typography marginTop="1rem">
                Cartas: {deck?.cards?.length || 0}
              </Typography>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setConfirmDelete(false)}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={onConfirmDelete}
            startIcon={deleteLoading ? <CircularProgress color="inherit" size={16} /> : <FaTrash />}
            color="error"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeckModal;
