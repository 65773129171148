import React, { useEffect, useState } from "react";
import {
  IconButton,
  Menu,
  Grid2,
  TextField,
  Button,
  Typography,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { AnimalNamesPtBr, AnimalsEnum } from "../AnimalBg/types";
import { PlayerIconSelectorContainer } from "./PlayerIconSelector.styles";
import AnimalBg from "../AnimalBg/AnimalBg";

const animalOptions = Object.values(AnimalsEnum);

type PlayerIconSelectorProps = {
  onChange: (value: { color: string; animal: string }) => void;
};

const PlayerIconSelector: React.FC<PlayerIconSelectorProps> = ({
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAnimal, setSelectedAnimal] = useState<AnimalsEnum>();
  const [color, setColor] = useState<string>();
  const [search, setSearch] = useState("");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAnimalSelect = (animal: string) => {
    setSelectedAnimal(animal as AnimalsEnum);
    handleClose();
  };

  useEffect(() => {
    const avatar = JSON.parse(localStorage.getItem("avatar") || "null");
    if (avatar?.animal && avatar.color) {
      setSelectedAnimal(avatar.animal);
      setColor(avatar.color);
    } else {
      setSelectedAnimal(
        animalOptions[Math.floor(Math.random() * animalOptions.length)],
      );
      setColor(
        `#${Math.floor(Math.random() * 16777215)
          .toString(16)
          .slice(0, 6)
          .padStart(6, "0")}`,
      );
    }
  }, []);

  useEffect(() => {
    if (color && selectedAnimal) {
      onChange({ color, animal: selectedAnimal });
      localStorage.setItem(
        "avatar",
        JSON.stringify({ color, animal: selectedAnimal }),
      );
    }
  }, [color, selectedAnimal, onChange]);

  return (
    <PlayerIconSelectorContainer>
      <legend>
        <Typography variant="caption" color="textSecondary">
          Seu avatar *
        </Typography>
      </legend>

      <TextField
        label="Cor"
        name="color"
        type="color"
        value={color}
        onChange={(e) => setColor(e.target.value)}
        fullWidth
        variant="standard"
      />

      <Button
        onClick={handleClick}
        startIcon={
          <AnimalBg name={selectedAnimal} color={color} size="40px" dance />
        }
        fullWidth
        color="inherit"
      >
        <Typography>
          {!!selectedAnimal && AnimalNamesPtBr[selectedAnimal]}
        </Typography>
      </Button>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        <MenuItem>
          <TextField
            label="Pesquisar"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
          />
        </MenuItem>
        <Grid2
          container
          spacing={1}
          style={{
            padding: "10px",
            maxWidth: 600,
          }}
        >
          {animalOptions
            .filter((animal) =>
              `${animal},${AnimalNamesPtBr[animal]}`
                .toUpperCase()
                .includes(search.toUpperCase()),
            )
            .map((animal) => (
              <Grid2 component="div" key={animal}>
                <Tooltip
                  placement="top"
                  title={
                    <Typography style={{ textTransform: "capitalize" }}>
                      {AnimalNamesPtBr[animal]}
                    </Typography>
                  }
                >
                  <IconButton onClick={() => handleAnimalSelect(animal)}>
                    <AnimalBg name={animal} color={color} size="40px" />
                  </IconButton>
                </Tooltip>
              </Grid2>
            ))}
        </Grid2>
      </Menu>
    </PlayerIconSelectorContainer>
  );
};

export default PlayerIconSelector;
