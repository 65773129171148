import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider, THEME_ID } from '@mui/material/styles';
import { defaultTheme } from "./style/theme";
import "./App.css";
import Router from "./routes";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ToastContainer } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { materialTheme } from "./style/materialTheme";

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <MuiThemeProvider theme={{ [THEME_ID]: materialTheme }}>
        <ToastContainer autoClose={2000} />
        <Router />
      </MuiThemeProvider>
    </ThemeProvider>
  );
}

export default App;
