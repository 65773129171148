import React from 'react';
import AdminRouter from './AdminRoutes';
import GameRouter from './GameRouter';

const Router: React.FC = () => {
  return (
    <>
      <AdminRouter />
      <GameRouter />
    </>
  );
}

export default Router;