import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PATHS } from './Paths';
import Game from '../components/Game';
import Home from '../components/Home/Home';
import { SessionContextProvider } from '../contexts/SessionContext';
import Redirect from './Redirect';

const GameRouter: React.FC = () => {
  return (
    <SessionContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path={PATHS.GAME.HOME()} Component={Home} />
          <Route path={PATHS.GAME.PLAY()} Component={Game} />
          <Route path={PATHS.GAME.GAME()} Component={() => <Redirect path={PATHS.GAME.HOME()} />} />
        </Routes>
      </BrowserRouter>
    </SessionContextProvider>
  );
}

export default GameRouter;
