import axios from "axios";

export const GameClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL || "/api"}/game`,
  withCredentials: true,
});

GameClient.interceptors.request.use((request) => {
  request.headers.set("name", localStorage.getItem("name"));
  return request;
});
