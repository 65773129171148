import React, { useEffect, useRef, useState } from "react";
import { HandContainer } from "./Hand.styles";
import Card from "../Card";
import { CARD_TYPE, GameCard } from "../../types/Card";
import { useGameContext } from "../../contexts/GameContext";
import { usePlayCards } from "../../hooks/Game/usePlayCard";
import { useSessionContext } from "../../contexts/SessionContext";
import JoinModal from "../JoinModal/JoinModal";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes";
import { useSoundContext } from "../../contexts/SoundContext";

type HandProps = {};

const Hand: React.FC<HandProps> = () => {
  const [openCardIndex, setOpenCardIndex] = useState(-1);
  const lastQuestion = useRef<GameCard>();

  const navigate = useNavigate();

  const {
    code,
    showHand,
    handCards,
    tableAnswers,
    setShowHand,
    setHandCards,
    setSelectedCards,
    questionCard,
    selectedCards,
    votingStarted,
    votingFinished,
    answerStartedTime,
    finished,
    players,
    setTableAnswers,
    getGameState,
  } = useGameContext();
  const { playCardAudio } = useSoundContext();
  const { user_id: userId, user } = useSessionContext();

  const { playCards, loading, error: playError } = usePlayCards(code);

  const hasPlayError = !!playError;

  useEffect(() => {
    if (hasPlayError) {
      getGameState(code);
    }
  }, [hasPlayError, getGameState, code]);

  useEffect(() => {
    setOpenCardIndex(-1);
  }, [showHand]);

  useEffect(() => {
    if (answerStartedTime && lastQuestion.current?.id !== questionCard?.id) {
      setShowHand(!votingStarted);
      lastQuestion.current = questionCard;
      setSelectedCards([]);
    }
  }, [
    answerStartedTime,
    questionCard,
    setSelectedCards,
    setShowHand,
    votingStarted,
  ]);

  useEffect(() => {
    if (
      !loading &&
      (!votingStarted || (votingFinished && !tableAnswers.length))
    ) {
      setShowHand(
        !!handCards?.length &&
          !tableAnswers.find(({ user_id }) => user_id === userId),
      );
    }
  }, [
    handCards?.length,
    loading,
    setShowHand,
    tableAnswers,
    userId,
    votingFinished,
    votingStarted,
  ]);

  useEffect(() => {
    if (!tableAnswers?.length) {
      setShowHand(true);
    }
  }, [setShowHand, tableAnswers?.length]);

  const onConfirmSelectCard = async (card: GameCard) => {
    if (selectedCards.find(({ id }) => card.id === id)) {
      setOpenCardIndex(-1);
      return;
    }

    const newSelectedCards = [...selectedCards, card];

    if ((questionCard?.answers || 0) > newSelectedCards.length) {
      setOpenCardIndex(-1);
      setSelectedCards(newSelectedCards);
      return;
    }
    setShowHand(false);
    setTableAnswers([
      {
        cards: newSelectedCards,
        user_id: userId!,
        votes: [],
        user,
      },
    ]);
    setSelectedCards([]);
    playCardAudio();
    const hand = await playCards(newSelectedCards.map(({ id }) => id));
    if (hand?.length) {
      setHandCards(hand as GameCard[]);
    }
  };

  const onCancelSelect = () => {
    setSelectedCards((s) =>
      s.filter((card) => card.id !== handCards[openCardIndex]?.id),
    );
    setOpenCardIndex(-1);
  };

  return (
    <>
      <HandContainer
        $count={handCards.length}
        $show={showHand && !!answerStartedTime && !finished}
      >
        {handCards.map((card, i) => (
          <Card
            handPosition={i}
            type={CARD_TYPE.ANSWER}
            totalCards={handCards.length}
            id={card.id}
            key={card.id}
            text={card.text}
            onOpen={() => setOpenCardIndex(i)}
            open={openCardIndex === i}
            onConfirmSelect={() => onConfirmSelectCard(card)}
            onCancelSelect={onCancelSelect}
          />
        ))}
      </HandContainer>

      <JoinModal
        code={code}
        show={
          !!(
            userId &&
            questionCard &&
            !players?.find(({ id }) => id === userId) &&
            !handCards?.length
          )
        }
        onClose={() => navigate(PATHS.GAME.HOME())}
        onJoin={() => window.location.reload()}
      />
    </>
  );
};

export default Hand;
