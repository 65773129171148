import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  AnswerCards,
  AnswersContainer,
  AnswerWrapper,
  Buttons,
  QRCodeContainer,
  QuestionContainer,
  TableContainer,
} from "./Table.styles";
import Card from "../Card";
import { useGameContext } from "../../contexts/GameContext";
import {
  Alert,
  Button,
  CircularProgress,
  Modal,
  Typography,
  Zoom,
} from "@mui/material";
import { useNextQuestion } from "../../hooks/Game/useNextQuestion";
import {
  FaCheck,
  FaShareAlt,
  FaCrown,
  FaPlay,
  FaQrcode,
  FaStepForward,
  FaVoteYea,
} from "react-icons/fa";
import { useSessionContext } from "../../contexts/SessionContext";
import { useVoteAnswer } from "../../hooks/Game/useVoteAnswer";
import { toast } from "material-react-toastify";
import { GameCard } from "../../types/Card";
import QRCode from "react-qr-code";
import { PATHS } from "../../routes";
import { useSoundContext } from "../../contexts/SoundContext";
import Clock from "../Clock/Clock";

type TableProps = {};

const Table: React.FC<TableProps> = () => {
  const [openAnswer, setOpenAnswer] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [visibleAnswers, setVisibleAnswers] = useState<any>({});
  const {
    questionCard,
    code,
    showHand,
    tableAnswers,
    selectedCards,
    setTableAnswers,
    votingStarted,
    votingFinished,
    adminId,
    answerStartedTime,
    onlinePlayers,
    finished,
    getGameState,
  } = useGameContext();
  const { playPopAudio, playClickAudio, playWinAudio } = useSoundContext();
  const { user_id: userId } = useSessionContext();
  const [voted, setVoted] = useState(false);
  const lastQuestion = useRef<GameCard>();
  const toastId = useRef<number | string>();

  const isAdmin = userId === adminId;

  const { nextQuestion, error: nextError } = useNextQuestion(code);
  const {
    voteAnswer,
    loading: voteLoading,
    error: voteError,
  } = useVoteAnswer(code);

  const hasError = !!nextError || !!voteError;

  useEffect(() => {
    if (hasError) {
      getGameState(code);
    }
  }, [hasError, getGameState, code]);

  const maxVotes = useMemo(
    () => Math.max(1, ...tableAnswers.map(({ votes }) => votes?.length || 0)),
    [tableAnswers],
  );

  const userHasWon = useMemo(
    () =>
      votingFinished &&
      !!tableAnswers.find(
        (ans) => ans.user_id === userId && maxVotes === ans.votes?.length,
      ),
    [maxVotes, tableAnswers, userId, votingFinished],
  );

  const handleNextQuestion = async () => {
    setLoading(true);
    setVisibleAnswers({});
    playPopAudio();
    await nextQuestion();
  };

  const handleVote = async (user_id: string) => {
    setVoted(true);
    setTimeout(() => setVoted(false), 2000);
    setTableAnswers((t) =>
      t.map((answer) => ({
        ...answer,
        votes:
          answer.user_id === user_id
            ? [...answer.votes, userId as string]
            : answer.votes,
      })),
    );
    playClickAudio();
    await voteAnswer(user_id);
  };

  const shareRoom = async () => {
    if (navigator?.share) {
      try {
        await navigator.share({
          title: "Pesadown",
          text: "O jogo de Cartas Pesadas (muitos Pesadas)!",
          url: `${window.location.origin}${PATHS.GAME.PLAY(code)}`,
        });
      } catch (error) {
        toast.error("Não foi possível compartilhar");
      }
    } else {
      toast.error("Seu navegador não suporta compartilhamento");
    }
  };

  const userHasVoted = useMemo(
    () =>
      !!tableAnswers?.find((answer: any) => answer?.votes?.includes?.(userId)),
    [tableAnswers, userId],
  );

  const userHasPlayed = useMemo(
    () => !!tableAnswers?.find((answer: any) => answer?.user_id === userId),
    [tableAnswers, userId],
  );

  useEffect(() => {
    if (userHasWon) {
      playWinAudio();
    }
  }, [playWinAudio, questionCard?.id, userHasWon]);

  useEffect(() => {
    setOpenAnswer(-1);

    if (!tableAnswers.length) {
      setVisibleAnswers({});
    }

    for (let i = 0; i < tableAnswers.length; i++) {
      setTimeout(
        () =>
          setVisibleAnswers((v: any) => ({
            ...v,
            [i]: true,
          })),
        i * 300,
      );
    }
  }, [tableAnswers.length]);

  useEffect(() => {
    if (votingFinished && isAdmin) {
      if (toastId.current && toast.isActive(toastId.current)) {
        try {
          toast.dismiss(toastId.current);
          toastId.current = undefined;
        } catch {
          //
        }
      }
      toastId.current = toast.dark("Passe para a próxima pergunta.");
    }
  }, [isAdmin, votingFinished]);

  useEffect(() => {
    if (
      votingStarted &&
      onlinePlayers.length > 1 &&
      tableAnswers.length &&
      !votingFinished
    ) {
      if (toastId.current && toast.isActive(toastId.current)) {
        try {
          toast.dismiss(toastId.current);
          toastId.current = undefined;
        } catch {
          //
        }
      }
      toastId.current = toast.dark("Vote na melhor resposta.");
    }
  }, [
    onlinePlayers.length,
    tableAnswers.length,
    votingFinished,
    votingStarted,
  ]);

  const gameStarted = !!answerStartedTime;

  useEffect(() => {
    if (gameStarted && lastQuestion.current?.id !== questionCard?.id) {
      setLoading(true);
      setTimeout(() => setLoading(false), 500);
      lastQuestion.current = questionCard;
    }
  }, [gameStarted, questionCard]);

  useEffect(() => {
    if (!gameStarted) return;
    if (toastId.current && toast.isActive(toastId.current)) {
      toast.dismiss(toastId.current);
      toastId.current = undefined;
    }
    toastId.current = toast.dark("O jogo começou.");
  }, [gameStarted]);

  const cardsLeftToSelect = (questionCard?.answers || 1) - selectedCards.length;

  if (finished) {
    return null;
  }

  return (
    <TableContainer $loading={loading}>
      <Buttons>
        <Button
          onClick={shareRoom}
          variant="contained"
          color="inherit"
          size="small"
        >
          <FaShareAlt size={12} />
        </Button>
        <Button
          variant="contained"
          color="inherit"
        >
          {code}
        </Button>
        <Button
          onClick={() => setShowQR(true)}
          variant="contained"
          color="inherit"
          size="small"
        >
          <FaQrcode size={12} />
        </Button>
      </Buttons>
      <center>
        {isAdmin &&
          (gameStarted ? (
            <Zoom
              in={
                votingFinished ||
                (votingStarted &&
                  (!onlinePlayers.filter(({ id }) => id !== userId).length ||
                    !tableAnswers?.length))
              }
              timeout={100}
              unmountOnExit
              style={{
                transitionDelay: "200ms",
              }}
            >
              <Button
                onClick={handleNextQuestion}
                variant="contained"
                color="success"
                endIcon={<FaStepForward size={14} />}
              >
                Próxima pergunta
              </Button>
            </Zoom>
          ) : (
            <Button
              onClick={handleNextQuestion}
              variant="contained"
              color="success"
              endIcon={<FaPlay size={14} />}
            >
              Começar partida
            </Button>
          ))}

        <Clock />
      </center>
      <QuestionContainer $loading={loading || !gameStarted}>
        {questionCard && (
          <Card {...questionCard} text={loading ? "" : questionCard.text} />
        )}
      </QuestionContainer>
      {showHand && !loading && gameStarted && !userHasPlayed && (
        <center>
          <Typography>
            Selecione {cardsLeftToSelect} resposta
            {cardsLeftToSelect === 1 ? "" : "s"}.
          </Typography>
          {!!tableAnswers?.length && (
            <Typography>
              {tableAnswers.length} jogadores já selecionaram suas respostas.
            </Typography>
          )}
        </center>
      )}
      {!isAdmin && !gameStarted && (
        <center>
          <div style={{ maxWidth: 500 }}>
            <Typography marginBottom="2rem">
              Aguarde o dono da sala iniciar a partida.
            </Typography>
            <CircularProgress color="info" />
          </div>
        </center>
      )}
      <AnswersContainer $show={!showHand && !!tableAnswers?.length}>
        {tableAnswers?.map(
          ({ user_id: cardPlayerId, cards, votes, user }, i) => (
            <AnswerCards
              key={cardPlayerId}
              $count={cards?.length || 1}
              $visible={visibleAnswers[i]}
              $voteStarted={votingStarted}
              $won={onlinePlayers.length > 1 && votingFinished && maxVotes === votes?.length}
            >
              {onlinePlayers.length > 1 && votingFinished && (
                <Alert
                  icon={maxVotes === votes?.length && <FaCrown />}
                  severity="success"
                  variant={maxVotes === votes?.length ? "filled" : "standard"}
                >
                  <Typography variant="body1" fontWeight="600">
                    {votes?.length}{" "}votos
                  </Typography>
                </Alert>
              )}
              <Zoom
                in={
                  onlinePlayers.length > 1 && votingStarted && !votingFinished
                }
                timeout={100}
                unmountOnExit
                style={{
                  transitionDelay: "200ms",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => handleVote(cardPlayerId!)}
                  endIcon={
                    (cardPlayerId !== userId || onlinePlayers.length === 2) &&
                    (voteLoading ? (
                      <CircularProgress color="inherit" size={14} />
                    ) : votes?.includes(userId!) ? (
                      <FaCheck size={12} />
                    ) : (
                      <FaVoteYea />
                    ))
                  }
                  disabled={
                    voteLoading ||
                    voted ||
                    userHasVoted ||
                    (cardPlayerId === userId && onlinePlayers.length !== 2)
                  }
                >
                  {cardPlayerId === userId && onlinePlayers.length !== 2
                    ? "Sua resposta"
                    : votes?.includes(userId!)
                      ? "Votou"
                      : "Votar"}
                </Button>
              </Zoom>
              {cards?.map(
                (cardProps) =>
                  !!cardProps && (
                    <AnswerWrapper
                      key={cardProps.id}
                      $open={openAnswer === i}
                      onClick={() => setOpenAnswer((o) => (o === i ? -1 : i))}
                    >
                      <Card
                        {...(cardProps as any)}
                        player={
                          !!votingFinished &&
                          onlinePlayers.length > 1 &&
                          user?.name
                        }
                      />
                    </AnswerWrapper>
                  ),
              )}
            </AnswerCards>
          ),
        )}
      </AnswersContainer>

      <Modal open={showQR} onClose={() => setShowQR(false)}>
        <Zoom in={showQR} timeout={100} style={{ transitionDelay: "100ms" }}>
          <QRCodeContainer>
            <QRCode
              value={`${window.location.origin}${PATHS.GAME.PLAY(code)}`}
            />
          </QRCodeContainer>
        </Zoom>
      </Modal>
    </TableContainer >
  );
};

export default Table;
