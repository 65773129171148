export const PATHS = {
  ADMIN: {
    HOME: () => "/admin",
    LIST: () => "/admin/list",
  },
  GAME: {
    HOME: () => "/",
    GAME: () => `/game`,
    PLAY: (code?: string) => `/game/${code || ":code"}`,
  },
};
