import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CircularProgress,
  DialogActions,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { FaPlay, FaTimes } from "react-icons/fa";
import {
  JoinModalContainer,
  JoinModalForm,
  JoinModalHeader,
} from "./JoinModal.styles";
import { useJoinGame } from "../../hooks/Game/useJoinGame";
import PlayerIconSelector from "../PlayerIconSelector/PlayerIconSelector";

type JoinModalProps = {
  code?: string;
  show: boolean;
  onJoin: (code: string) => void;
  onClose: () => void;
};

const JoinModal: React.FC<JoinModalProps> = ({
  code,
  show,
  onJoin,
  onClose,
}) => {
  const [visible, setVisible] = useState(false);
  const [gameCode, setGameCode] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState({ color: "", animal: "" });

  const { loading, error, joinGame } = useJoinGame();

  useEffect(() => {
    setVisible(show);
  }, [show]);

  useEffect(() => {
    setGameCode(code || "");
  }, [code]);

  const valid = useMemo(
    () => !!gameCode && name?.length > 3 && (!password || password.length > 3),
    [gameCode, name?.length, password],
  );

  useEffect(() => {
    if (name?.length > 3) {
      localStorage.setItem("name", name);
    }
  }, [name]);

  useEffect(() => {
    setName(localStorage.getItem("name") || "");
  }, []);

  const handleSubmit = async () => {
    if (!valid) return;

    const game = await joinGame(
      gameCode,
      {
        name,
        avatar: avatar.animal,
        color: avatar.color,
      },
      password,
    );
    if (game) {
      onJoin(gameCode);
    }
  };

  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <JoinModalContainer>
        <Card>
          <CardContent>
            <JoinModalHeader>
              <Typography variant="h5">Deseja entrar na partida?</Typography>
              <Button variant="text" color="inherit" onClick={onClose}>
                <FaTimes size={20} />
              </Button>
            </JoinModalHeader>

            <JoinModalForm>
              <PlayerIconSelector onChange={setAvatar} />

              <TextField
                label="Seu nome"
                variant="outlined"
                value={name}
                fullWidth
                error={name.length < 4}
                onChange={(event) => setName(event.target.value)}
                required
              />

              <TextField
                label="Código"
                variant="outlined"
                value={gameCode}
                onChange={(event) =>
                  setGameCode(event.target.value.toUpperCase())
                }
                fullWidth
                disabled={!!code}
                required
              />

              <TextField
                label="Senha da sala (opcional)"
                variant="outlined"
                value={password}
                fullWidth
                onChange={(event) => setPassword(event.target.value)}
              />

              {!!error?.message && <Alert color="error">{error.message}</Alert>}
            </JoinModalForm>
          </CardContent>
          <DialogActions>
            <Button
              onClick={onClose}
              variant="outlined"
              startIcon={<FaTimes />}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              startIcon={
                loading ? (
                  <CircularProgress color="inherit" size={16} />
                ) : (
                  <FaPlay size={16} />
                )
              }
              disabled={!valid}
            >
              Entrar
            </Button>
          </DialogActions>
        </Card>
      </JoinModalContainer>
    </Modal>
  );
};

export default JoinModal;
