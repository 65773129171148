import { useCallback, useState } from "react";
import { CrudClient } from "../../../api/CrudClient";
import { API_ROUTES } from "../../../api/routes";
import { AxiosError } from "axios";
import { toast } from "material-react-toastify";

export const useDeleteCard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  const deleteCard = useCallback(async (id: string) => {
    setError(undefined);
    setLoading(true);
    try {
      const { data, status } = await CrudClient.delete(API_ROUTES.CARD(id));
      setLoading(false);
      toast.success("Carta excluída com sucesso.");
      return !!data || status === 200;
    } catch (e) {
      const error = e as AxiosError<any, any>;
      error.message = error.response?.data?.message || error.message;
      setError(e as any);
    }
    setLoading(false);
  }, []);

  return { error, deleteCard, loading };
};
