import { createContext, useContext } from "react";
import { Session } from "../types/Session";
import { useSession } from "../hooks/Game/useSession";

type SessionContextProps = Partial<Session & {
  getSession: () => void
}>;

const SessionContext = createContext<SessionContextProps>({});

export const SessionContextProvider: React.FC<{
  children: Array<React.ReactNode> | React.ReactNode,
}> = ({ children }) => {
  const { session, getSession } = useSession();

  return (
    <SessionContext.Provider value={{
      ...(session || {}),
      getSession
    }}>
      {children}
    </SessionContext.Provider>
  );
}

export const useSessionContext = () => {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error("useSessionContext needs to be under a SessionContextProvider");
  }

  return context;
}
