import styled from "styled-components";

export const JoinModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  max-width: 90vw;
  width: 400px;
`;

export const JoinModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;

  button {
    min-width: unset;
  }
`;

export const JoinModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const DecksColumnsContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  > div {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        label {
          margin: 0;
        }
      }
    }

    flex: 1;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
