import React, { useState } from "react";
import { HomeContainer, StyledCardContent } from "./Home.styles";
import { Button, Card, Typography } from "@mui/material";
import { PATHS } from "../../routes";
import { FaPlus, FaSignInAlt } from "react-icons/fa";
import GameSettingsModal from "../GameSettingsModal/GameSettingsModal";
import JoinModal from "../JoinModal/JoinModal";

const Home: React.FC = () => {
  const [joinGameModal, setJoinGameModal] = useState(false);
  const [newGameModal, setNewGameModal] = useState(false);

  return (
    <HomeContainer>
      <Card>
        <StyledCardContent>
          <div>
            <Typography variant="h4">Pesadown</Typography>
            <Typography variant="body2">
              Pesadown é um jogo de cartas irreverente e divertido baseado em
              humor negro, inspirado em Cartas Contra a Humanidade. Jogue online
              com amigos!
            </Typography>
          </div>

          <Button
            onClick={() => setJoinGameModal(true)}
            variant="contained"
            startIcon={<FaSignInAlt />}
            fullWidth
          >
            Entrar em uma partida
          </Button>

          <Button
            variant="contained"
            onClick={() => setNewGameModal(true)}
            startIcon={<FaPlus />}
            color="success"
            fullWidth
          >
            Criar nova partida
          </Button>
        </StyledCardContent>
      </Card>

      <img src="/icon.svg" alt="Pesadown logo" />

      <GameSettingsModal
        visible={newGameModal}
        onClose={() => setNewGameModal(false)}
      />

      <JoinModal
        show={joinGameModal}
        onClose={() => setJoinGameModal(false)}
        onJoin={(code) => window.location.assign(PATHS.GAME.PLAY(code))}
      />
    </HomeContainer>
  );
};

export default Home;
