import { Alert, Button, ButtonGroup, Card, CardContent, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CardModalContainer, CardModalForm, CardModalHeader } from './CardModal.styles';
import { CARD_TYPE, GameCard } from '../../../types/Card';
import { FaSave, FaTimes, FaTrash } from 'react-icons/fa';
import { typeChipColor, typeMap } from '../../../types/CardType';
import { useCreateCard } from '../../../hooks/CRUD/card/useCreateCard';
import { useUpdateCard } from '../../../hooks/CRUD/card/useUpdateCard';
import { useDeleteCard } from '../../../hooks/CRUD/card/useDeleteCard';
import { DeckData } from '../../../types/Deck';
import { useAddCardToDeck } from '../../../hooks/CRUD/deck/useAddCardToDeck';

type CardModalProps = {
  visible?: boolean;
  onClose?: () => void;
  card?: Partial<GameCard>;
  onSave?: () => void;
  decks?: DeckData[];
}

const CardModal: React.FC<CardModalProps> = ({ visible = false, onClose, card, onSave, decks }) => {
  const [text, setText] = useState("");
  const [type, setType] = useState(CARD_TYPE.ANSWER);
  const [answers, setAnswers] = useState(1);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deck, setDeck] = useState<DeckData>();

  const { loading: createLoading, error: createError, createCard } = useCreateCard();
  const { loading: updateLoading, error: updateError, updateCard } = useUpdateCard();
  const { loading: deleteLoading, deleteCard } = useDeleteCard();
  const { loading: loadingDeck, addCardToDeck } = useAddCardToDeck();

  const loading = createLoading || updateLoading || loadingDeck;
  const error = createError || updateError;

  useEffect(() => {
    if (card) {
      setText(card.text || "");
      setType(card.type || CARD_TYPE.ANSWER);
      setAnswers(card.answers || 1);
    }
  }, [card]);

  const reset = () => {
    setText("");
    setType(CARD_TYPE.ANSWER);
    setAnswers(1);
  }

  const handleClose = () => {
    reset();
    onClose?.();
  }

  const handleSubmit = async () => {
    if (!text) return;
    let data;
    if (card?.id) {
      data = await updateCard(card.id, {
        text,
        answers
      });
    } else {
      data = await createCard({
        type,
        text,
        answers
      });

      if (deck?.id && data?.id) {
        await addCardToDeck(deck.id, data.id);
      }
    }
    if (data?.id) {
      onSave?.();
      handleClose();
    }
  }

  const onConfirmDelete = async () => {
    if (card?.id) {
      const data = await deleteCard(card.id);
      if (data) {
        setConfirmDelete(false);
        onSave?.();
        handleClose();
      }
    }
  }

  return (
    <>
      <Modal
        open={visible}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <CardModalContainer>
          <Card>
            <CardContent>
              <CardModalHeader>
                <Typography variant="h4">
                  {card?.id ? "Editar" : "Criar"} carta
                </Typography>
                <Button variant="text" color="inherit" onClick={onClose}>
                  <FaTimes size={20} />
                </Button>
              </CardModalHeader>

              <CardModalForm>
                {!!error && (
                  <Alert color="error">
                    {error.message}
                  </Alert>
                )}

                <ButtonGroup fullWidth color="info">
                  {Object.values(CARD_TYPE).map((cardType) => (
                    <Button
                      key={cardType}
                      variant={type === cardType ? "contained" : "text"}
                      onClick={() => setType(cardType)}
                      disabled={!!card?.id}
                    >
                      {typeMap[cardType]}
                    </Button>
                  ))}
                </ButtonGroup>

                <TextField
                  label={`Texto da ${typeMap[type]?.toLowerCase()}`}
                  variant="filled"
                  multiline
                  value={text}
                  fullWidth
                  onChange={(event) => setText(event.target.value.replace(/\n/g, ""))}
                  minRows={3}
                />

                {type === CARD_TYPE.QUESTION && (
                  <FormControl fullWidth>
                    <InputLabel id="answers-label">Quantas respostas o jogador deve selecionar?</InputLabel>
                    <Select
                      label="Quantas respostas o jogador deve selecionar?"
                      value={answers}
                      onChange={(event) => setAnswers(+event.target.value)}
                      labelId="answers-label"
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                    </Select>
                  </FormControl>
                )}

                {!card?.id && (
                  <FormControl fullWidth>
                    <InputLabel id="answers-label">Baralho</InputLabel>
                    <Select
                      label="Baralho"
                      value={deck?.id || ""}
                      onChange={(event) => setDeck(decks?.find(({ id }) => id === event.target.value))}
                      labelId="answers-label"
                    >
                      <MenuItem value="">(Todos)</MenuItem>
                      {decks?.map(({ id, name, cards }) => (
                        <MenuItem key={id} value={id}>{name} ({cards?.length || 0})</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </CardModalForm>
            </CardContent>
            <DialogActions>
              {!!card?.id && (
                <Button onClick={() => setConfirmDelete(true)} variant="contained" color="error" disabled={loading}>
                  Excluir
                </Button>
              )}
              <Button onClick={handleClose} variant="outlined" disabled={loading}>
                Cancelar
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                startIcon={loading ? <CircularProgress color="inherit" size={16} /> : <FaSave />}
                disabled={!text}
              >
                Salvar
              </Button>
            </DialogActions>
          </Card>
        </CardModalContainer>
      </Modal>

      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogTitle>Tem certeza que deseja excluir essa carta?</DialogTitle>
        <DialogContent>
          <Card>
            <CardContent>
              <Stack direction="row" alignItems="center" gap="1rem">
                <Chip color={typeChipColor[type]} label={typeMap[type]} />
                {type === CARD_TYPE.QUESTION && (
                  <Chip color="info" label={`Respostas: ${answers}`} />
                )}
              </Stack>
              <Typography marginTop="1rem">
                {text}
              </Typography>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setConfirmDelete(false)}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={onConfirmDelete}
            startIcon={deleteLoading ? <CircularProgress color="inherit" size={16} /> : <FaTrash />}
            color="error"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CardModal;
