import { CARD_TYPE } from "../types/Card";

export const API_ROUTES = {
  CARD: (id: string) => `/cards/${id}`,
  CARDS: (type?: CARD_TYPE) => `/cards${type ? `?type=${type}` : ""}`,
  DECK: (id: string) => `/decks/${id}`,
  DECK_CARD: (id: string, cardId: string) => `/decks/${id}/card/${cardId}`,
  DECKS: () => `/decks`,
  GAME: (code: string) => `/${code}`,
  SESSION: () => `/session`,
  NEW: () => `/new`,
  JOIN: (code: string) => `/${code}/join`,
  SETTINGS: (code: string) => `/${code}/settings`,
  START_VOTE: (code: string) => `/${code}/start-vote`,
  VOTE: (code: string) => `/${code}/vote`,
  PLAY: (code: string) => `/${code}/play`,
  NEXT: (code: string) => `/${code}/next`,
  FINISH: (code: string) => `/${code}/finish`,
  MESSAGE: (code: string) => `/${code}/message`,
};
