import { useCallback, useState } from "react";
import { Deck } from "../../../types/Deck";
import { CrudClient } from "../../../api/CrudClient";
import { API_ROUTES } from "../../../api/routes";
import { AxiosError } from "axios";
import { toast } from "material-react-toastify";

export const useCreateDeck = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  const createDeck = useCallback(async (deck: Partial<Deck>) => {
    setError(undefined);
    setLoading(true);
    try {
      const { data } = await CrudClient.post(API_ROUTES.DECKS(), deck);
      setLoading(false);
      toast.success("Baralho criado com sucesso.");
      return data;
    } catch (e) {
      const error = e as AxiosError<any, any>;
      error.message = error.response?.data?.message || error.message;
      setError(e as any);
    }
    setLoading(false);
  }, []);

  return { error, createDeck, loading };
};
