import React, { useMemo } from 'react';
import { ButtonsWrapper, CardContainer, CardWrapper, SelectedIcon } from './Card.styles';
import { CARD_TYPE, GameCard } from '../../types/Card';
import { useGameContext } from '../../contexts/GameContext';
import { FaTimes, FaCheck } from 'react-icons/fa';
import { Fab, Typography, Zoom } from '@mui/material';

export type CardProps = GameCard & {
  totalCards?: number;
  handPosition?: number;
  onOpen?: () => void;
  onConfirmSelect?: () => void;
  onCancelSelect?: () => void;
  open?: boolean;
  player?: string;
}

const Card: React.FC<CardProps> = ({
  handPosition,
  totalCards,
  type = CARD_TYPE.ANSWER,
  text,
  onOpen,
  id,
  onConfirmSelect,
  onCancelSelect,
  open,
  player
}) => {
  const { selectedCards } = useGameContext();

  const selected = !!selectedCards?.find((c) => id === c.id);

  const textWithUnderscore = useMemo(() => {
    let returnText = text;
    while (returnText.includes("__")) {
      returnText = returnText.replace(/__/g, "_");
    }
    return returnText;
  }, [text]);

  return (
    <CardWrapper onClick={onOpen} $open={open} $handPosition={handPosition} $totalCards={totalCards} onTouchStart={onOpen}>
      <CardContainer
        $selected={selected}
        $type={type}
        $handPosition={handPosition}
        $totalCards={totalCards}
        $open={open}
        className={open ? "open" : ""}
      >
        <div>
          {textWithUnderscore.split("_").map((text, i) =>
            <>
              {(textWithUnderscore.startsWith("_") || !!i) && (
                <span style={{ borderBottom: "1px solid white", width: "50px", display: "inline-block" }}></span>
              )}
              {text}
            </>
          )}
        </div>

        {selected && (
          <SelectedIcon>
            <FaCheck size={50} />
          </SelectedIcon>
        )}

        {!!player && (
          <center style={{ marginBottom: "-0.5rem" }}>
            <Typography color="textSecondary" variant="caption">
              {player}
            </Typography>
          </center>
        )}
      </CardContainer>

      <ButtonsWrapper $visible={open} onClick={(e) => e.stopPropagation()}>
        <Zoom
          in={open}
          timeout={300}
          unmountOnExit
          style={{
            transitionDelay: "200ms",
          }}
        >
          <Fab
            color="error"
            aria-label="add"
            onClick={onCancelSelect}
            size="medium"
          >
            <FaTimes size={18} />
          </Fab>
        </Zoom>
        <Zoom
          in={open}
          timeout={300}
          style={{
            transitionDelay: "400ms",
          }}
          unmountOnExit
        >
          <Fab
            color="success"
            aria-label="add"
            onClick={onConfirmSelect}
            size="medium"
          >
            <FaCheck size={18} />
          </Fab>
        </Zoom>
      </ButtonsWrapper>
    </CardWrapper>
  );
}

export default Card;
