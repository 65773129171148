import { materialTheme } from "./materialTheme";

export const defaultTheme = {
  ...materialTheme,
  appBackground: "linear-gradient(135deg, #1E90FF, #4682B4, #87CEFA)",
  cards: {
    background: {
      question: "linear-gradient(135deg, #222222, #333333, #000000)",
      answer: "linear-gradient(135deg, #F5F5F5, #DCDCDC, #FFFFFF)",
    },
    border: {
      question: "transparent",
      answer: "#DCDCDC",
    },
    text: {
      question: "#FFFFFF",
      answer: "#2B2D42",
    },
  },
};
