import styled from "styled-components";
import { CardContainer } from "../Card/Card.styles";

export const HandContainer = styled.div<{ $count: number; $show: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: repeat(
    ${({ $count }) =>
      `${$count}, min(var(--card-size), ${Math.round(100 / ($count || 1))}%)`}
  );
  justify-content: center;
  align-items: center;
  padding: ${({ $show }) => ($show ? "40px" : "0px")};
  transition: 300ms cubic-bezier(0.46, -0.39, 0.57, 1.32);
  height: ${({ $show }) => ($show ? "300px" : "0px")};
  overflow: visible;
  opacity: ${({ $show }) => ($show ? 1 : 0)};

  ${CardContainer}${CardContainer} {
    cursor: pointer;
  }

  ${CardContainer}:hover:not(.open) {
    rotate: 0deg;
    translate: 0 -20%;
    box-shadow: 5px 5px 15px -5px ${({ theme }) => theme.palette.common.black};
  }

  @media screen and (max-width: 600px) {
    left: ${({ $show }) => ($show ? "calc(var(--card-size) / 6)" : "")};
    right: ${({ $show }) => ($show ? "calc(var(--card-size) / 7)" : "")};
  }
`;
