import React, { useEffect, useState } from 'react';
import { ActionsContainer, FinishGameContainer, FinishGameHeader } from './FinishGame.styles';
import { Button, CircularProgress, Fab, Modal, Typography, Zoom } from '@mui/material';
import { FaChevronLeft, FaFlagCheckered, FaSignOutAlt, FaSkullCrossbones } from 'react-icons/fa';
import { useGameContext } from '../../contexts/GameContext';
import { useSessionContext } from '../../contexts/SessionContext';
import { useFinishGame } from '../../hooks/Game/useFinishGame';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../routes';

const FinishGame: React.FC = () => {
  const { user_id } = useSessionContext();
  const { code, finished, adminId, answerStartedTime } = useGameContext();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { finishGame } = useFinishGame(code);

  useEffect(() => {
    if (finished) {
      setOpen(false);
      setLoading(false);
    }
  }, [finished]);

  const isAdmin = user_id === adminId;

  const handleSubmit = async () => {
    if (!isAdmin) {
      navigate(PATHS.GAME.HOME());
    } else {
      setLoading(true);
      await finishGame();
    }
  }

  if (!code) {
    return null;
  }

  return (
    <>
      <Zoom
        in={!open && !!answerStartedTime && !finished}
        timeout={100}
        unmountOnExit
        style={{
          transitionDelay: "100ms",
          position: 'fixed',
          top: 65,
          right: 5,
          zIndex: 1,
        }}
      >
        <Fab
          color="error"
          aria-label="add"
          onClick={() => setOpen(true)}
          size="medium"
        >
          {isAdmin ? (
            <FaFlagCheckered size={18} />
          ) : (
            <FaSignOutAlt size={18} />
          )}
        </Fab>
      </Zoom>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Zoom
          in={open}
          timeout={100}
          style={{
            transitionDelay: "100ms"
          }}
        >
          <FinishGameContainer $open={open}>
            <FinishGameHeader>
              <Typography variant="h6">
                {isAdmin ? "Deseja terminar a partida?" : "Deseja sair da partida?"}
              </Typography>
            </FinishGameHeader>

            <ActionsContainer>
              <Button
                variant="contained"
                color="success"
                startIcon={<FaChevronLeft size={16} />}
                onClick={() => setOpen(false)}
                disabled={loading}
              >
                Jogar
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={
                  isAdmin ? (
                    loading ? <CircularProgress size={20} color="inherit" /> : <FaSkullCrossbones size={18} />
                  ) : (
                    <FaSignOutAlt size={18} />
                  )
                }
                onClick={handleSubmit}
                disabled={loading}
              >
                {isAdmin ? "Terminar" : "Sair"}
              </Button>
            </ActionsContainer>
          </FinishGameContainer>
        </Zoom>
      </Modal>
    </>
  );
}

export default FinishGame;