import React, { useEffect, useMemo, useState } from 'react';
import { useListCards } from '../../../hooks/CRUD/card/useListCards';
import { CardListContainer, Header } from './CardList.styles';
import { CARD_TYPE, GameCard } from '../../../types/Card';
import {
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { FaLayerGroup, FaPencilAlt, FaPlus } from 'react-icons/fa';
import CardModal from '../CardModal/CardModal';
import { typeMap } from '../../../types/CardType';
import { DeckData } from '../../../types/Deck';
import { useListDecks } from '../../../hooks/CRUD/deck/useListDecks';
import DeckModal from '../DeckModal/DeckModal';

const CardItem = React.lazy(() => import("./CardItem"));

const CardList: React.FC = () => {
  const [editingCard, setEditingCard] = useState<Partial<GameCard>>();
  const [editingDeck, setEditingDeck] = useState<Partial<DeckData>>();
  const [type, setType] = useState<CARD_TYPE>(CARD_TYPE.QUESTION);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState({
    [CARD_TYPE.ANSWER]: 0,
    [CARD_TYPE.QUESTION]: 0,
  });
  const [deck, setDeck] = useState<DeckData>();

  const { cards, loading, getCards } = useListCards(type);
  const { decks, getDecks } = useListDecks();

  useEffect(() => {
    if (!localStorage.getItem("client-password")) {
      localStorage.setItem("client-password", prompt("Senha") || "");
    }
  }, []);

  const deckMap = useMemo(() => {
    const map: {
      [cardId: string]: DeckData[],
    } = {};

    if (!decks) return map;

    decks.forEach((deck) => {
      deck.cards?.forEach((card) => {
        map[card] = [
          ...(map[card] || []),
          deck
        ]
      })
    });

    return map;
  }, [decks]);

  const deckColorMap = useMemo(() => {
    if (!decks) return {};

    const options = ["primary", "secondary", "error", "info", "success", "warning"];

    return decks.reduce((mem, deck, i) => ({
      ...mem,
      [deck.id]: options[i % options.length],
    }), {} as { [deckId: string]: string });
  }, [decks]);

  const items = useMemo(() =>
    cards?.filter(({ id, text }) => {
      if (deck?.id === "!") {
        return !decks?.find(({ cards }) => cards.includes(id));
      } else if (deck && !deck.cards?.includes(id)) {
        return false;
      }
      const lowerCase = text.toLowerCase();
      return !search || search.toLowerCase().split(" ").every((word) => id === word || lowerCase.includes(word));
    }).sort((a, b) => (
      a.text.localeCompare(b.text)
    )),
    [cards, deck, search, decks]
  );

  useEffect(() => {
    setCount((c) => ({
      ...c,
      [cards?.[0]?.type || ""]: cards?.length || 0
    }));
  }, [cards]);

  return (
    <div>
      <Typography variant="h2">
        Cartas
      </Typography>

      <Header>
        <Tabs onChange={(_, value) => Object.values(CARD_TYPE).includes(value) && setType(value)} value={type}>
          {Object.values(CARD_TYPE).map((cardType) => (
            <Tab
              key={cardType}
              value={cardType}
              label={`${typeMap[cardType]} (${count[cardType]})`}
            />
          ))}
        </Tabs>

        <div style={{ flex: 1 }}>
          <TextField
            label="Pesquisar"
            variant="filled"
            value={search}
            type="search"
            fullWidth
            onChange={(event) => setSearch(event.target.value.replace(/\n/g, ""))}
          />
        </div>

        <div style={{ flex: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="answers-label">Baralho</InputLabel>
            <Select
              label="Baralho"
              value={deck?.id || ""}
              onChange={(event) => setDeck(event.target.value === "!" ? ({ id: "!" } as any) : decks?.find(({ id }) => id === event.target.value))}
              labelId="answers-label"
            >
              <MenuItem value="">(Todos)</MenuItem>
              {decks?.map(({ id, name, cards }) => (
                <MenuItem key={id} value={id}>{name} ({cards?.length || 0})</MenuItem>
              ))}
              <MenuItem value="!">(Nenhum)</MenuItem>
            </Select>
          </FormControl>
        </div>

        <Button
          variant="contained"
          color="inherit"
          onClick={() => setEditingDeck(deck || {})}
          startIcon={(deck?.id?.length || 1) > 1 ? <FaPencilAlt size={14} /> : <FaLayerGroup size={14} />}
        >
          {(deck?.id?.length || 1) > 1 ? "Editar" : "Novo"} Baralho
        </Button>

        <Button
          variant="contained"
          onClick={() => setEditingCard({ type })}
          startIcon={<FaPlus />}
        >
          Nova Carta
        </Button>
      </Header>

      {loading && (
        <LinearProgress />
      )}

      <CardListContainer>
        {items?.map((card) => (
          <CardItem
            {...card}
            key={card.id}
            onEdit={() => setEditingCard({
              ...card
            })}
            deckMap={deckMap}
            decks={decks}
            reloadDecks={getDecks}
            deckColorMap={deckColorMap}
          />
        ))}
      </CardListContainer>

      <CardModal
        visible={!!editingCard}
        onSave={() => {
          getCards();
          getDecks();
        }}
        onClose={() => setEditingCard(undefined)}
        card={editingCard}
        decks={decks}
      />

      <DeckModal
        visible={!!editingDeck}
        onSave={() => getDecks()}
        onClose={() => setEditingDeck(undefined)}
        deck={editingDeck}
      />
    </div>
  );
}

export default CardList;
