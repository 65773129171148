import React, { useState } from 'react';
import { Fab, Zoom } from '@mui/material';
import { FaCog } from 'react-icons/fa';
import { useGameContext } from '../../contexts/GameContext';
import { useSessionContext } from '../../contexts/SessionContext';
import GameSettingsModal from '../GameSettingsModal/GameSettingsModal';

const SettingsButton: React.FC = () => {
  const { user_id } = useSessionContext();
  const { code, finished, adminId, settings } = useGameContext();

  const [open, setOpen] = useState(false);

  if (!code) {
    return null;
  }

  return (
    <>
      {!finished && (
        <Zoom
          in={!open}
          timeout={100}
          unmountOnExit
          style={{
            transitionDelay: "100ms",
            position: 'fixed',
            top: 5,
            right: 5,
            zIndex: 1,
          }}
        >
          <Fab
            color="default"
            aria-label="add"
            onClick={() => setOpen(true)}
            size="medium"
          >
            <FaCog size={18} />
          </Fab>
        </Zoom>
      )}

      <GameSettingsModal
        visible={open}
        code={code}
        settings={settings}
        onClose={() => setOpen(false)}
        isAdmin={adminId === user_id}
      />
    </>
  );
}

export default SettingsButton;