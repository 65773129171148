import styled from "styled-components";

export const CardContainer = styled.div<{
  $type: "question" | "answer";
  $handPosition?: number;
  $totalCards?: number;
  $open?: boolean;
  $selected?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  background: ${({ theme, $type }) => theme.cards.background[$type]};
  background-size: 400% 400%;
  animation: gradientAnimationDark 12s ease infinite;
  min-width: var(--card-size);
  min-height: var(--card-size);
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  color: ${({ theme, $type }) => theme.cards.text[$type]};
  border: 2px solid ${({ theme, $type }) => theme.cards.border[$type]};
  padding: 1rem 0.5rem;
  transition: 400ms cubic-bezier(0.46, -0.39, 0.57, 1.32);
  max-width: var(--card-size);
  font-size: var(--card-font);
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 500;
  user-select: none;
  overflow: hidden;
  translate: 0
    ${({ $handPosition, $totalCards, $open }) =>
      !$open && typeof $handPosition === "number" && $totalCards
        ? Math.abs($totalCards / 2 - ($handPosition + 0.5)) *
          Math.abs($totalCards / 2 - ($handPosition + 0.5)) *
          10
        : 0}px;
  rotate: ${({ $handPosition, $totalCards, $open, $selected }) =>
    typeof $handPosition === "number" &&
    !!$totalCards &&
    (!$open && !$selected
      ? `${(($handPosition + 0.5) / $totalCards) * 30 - 15}deg`
      : `${$handPosition + 0.5 < $totalCards / 2 ? "+" : "-"}360deg`)};
  box-shadow: ${({ $open, theme }) =>
    $open && `5px 5px 15px -5px ${theme.palette.common.black}`};
  -webkit-tap-highlight-color: transparent;

  > div {
    user-select: none;
  }

  ${({ $selected, theme }) =>
    $selected &&
    `
      translate: 0 -20%;
      box-shadow: 5px 5px 15px -5px ${theme.palette.common.black};
  `}

  @keyframes gradientAnimationDark {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const CardWrapper = styled.div<{
  $open?: boolean;
  $handPosition?: number;
  $totalCards?: number;
}>`
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 300ms cubic-bezier(0.46, -0.39, 0.57, 1.32);
  -webkit-tap-highlight-color: transparent;
  translate: ${({ $open, $handPosition, $totalCards }) =>
    $open
      ? `${
          typeof $handPosition === "number" && !!$totalCards
            ? ($totalCards / 2 - ($handPosition + 0.5)) * 100
            : 0
        }% -170%;`
      : "0% 0% !important"};
`;

export const ButtonsWrapper = styled.div<{
  $visible?: boolean;
}>`
  transition: 200ms cubic-bezier(0.46, -0.39, 0.57, 1.32);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: var(--card-size);
  position: absolute;
  bottom: -60px;
  scale: ${({ $visible }) => ($visible ? 1 : 0)};
`;

export const SelectedIcon = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  background: ${({ theme }) => theme.palette.success.light};
  color: white;
`;
