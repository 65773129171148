import styled from "styled-components";

export const CardModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  max-width: 100vw;
  width: 500px;
`;

export const CardModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;

  h1 {
    margin: 0;
    flex: 1;
  }

  button {
    min-width: unset;
  }
`;

export const CardModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;
