import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Redirect: React.FC<{ path: string }> = ({ path }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(path);
  }, [navigate, path]);

  return null;
}

export default Redirect;
