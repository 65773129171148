import styled from "styled-components";

export const HomeContainer = styled.div`
  min-height: -webkit-fill-available;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 2rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 150px;
    max-height: 150px;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
    padding-bottom: 25%;
  }
`;

export const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.75rem;
  text-align: center;
  max-width: 500px;
`;
