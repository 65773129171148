import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PATHS } from './Paths';
import CardList from '../components/CRUD/CardList/CardList';
import styled from 'styled-components';
import Redirect from './Redirect';

export const AdminPageContainer = styled.div`
  max-width: 1320px;
  margin: auto;
  padding: 0 1rem;
`;

const AdminRouter: React.FC = () => {
  return (
    <AdminPageContainer>
      <BrowserRouter>
        <Routes>
          <Route path={PATHS.ADMIN.HOME()} Component={() => <Redirect path={PATHS.ADMIN.LIST()} />} />
          <Route path={PATHS.ADMIN.LIST()} Component={CardList} />
        </Routes>
      </BrowserRouter>
    </AdminPageContainer>
  );
}

export default AdminRouter;
