import styled from "styled-components";

export const ChatContainer = styled.div<{ $open?: boolean }>`
  z-index: ${({ $open }) => ($open ? 2 : 1)};
  position: fixed;
  top: ${({ $open }) => ($open ? "50%" : "65px")};
  left: ${({ $open }) => ($open ? "50%" : "5px")};
  width: ${({ $open }) => ($open ? "90vw" : "50px")};
  max-width: 500px;
  height: ${({ $open }) => ($open ? "80vh" : "50px")};
  translate: ${({ $open }) => ($open ? " -50% -50%" : "0% 0%")};
  background: ${({ $open }) => ($open ? "white" : "none")};
  transition: 300ms cubic-bezier(0.46, -0.39, 0.57, 1.32);
  display: flex;
  flex-direction: column;
  padding: 0;
  box-shadow: ${({ $open }) =>
    $open ? "5px 5px 20px -5px #00000091" : "none"};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  overflow: visible;
`;

export const ChatHeader = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  button {
    background: none;
    border: none;
    outline: none;
    font-size: 16px;
    height: 40px;
    width: 40px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 0.25rem;
    color: black;
    -webkit-tap-highlight-color: transparent;
  }
`;

export const ChatMessagesList = styled.ul`
  list-style: none;
  padding: 1rem;
  margin: 0;
  overflow-y: auto;
  flex: 1;
`;

export const MessageWrapper = styled.div<{ $sent?: boolean }>`
  display: flex;
  flex-direction: ${({ $sent }) => ($sent ? "row-reverse" : "row")};
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0;
  gap: 0.5rem;
`;

export const AnimalContainer = styled.div`
  width: 25px;
  display: flex;
  justify-content: center;
`;

export const MessageContainer = styled.li<{
  $sent?: boolean;
  $isLast?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $sent }) => ($sent ? "flex-end" : "flex-start")};
  position: relative;
  flex: 1;

  &::before {
    content: " ";
    position: absolute;
    left: ${({ $sent }) => ($sent ? "" : "-8px")};
    right: ${({ $sent }) => ($sent ? "-8px" : "")};
    top: 6px;
    width: 0;
    height: 0;
    display: ${({ $isLast }) => ($isLast ? "block" : "none")};
    border-top: 4px solid transparent;
    border-right: ${({ $sent }) => ($sent ? "" : "10px solid #eee")};
    border-left: ${({ $sent }) => ($sent ? "10px solid #eee" : "")};
    border-bottom: 4px solid transparent;
  }
`;

export const Message = styled.div`
  max-width: 80%;
  background: #eeeeee;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: 0.25rem 0.5rem;
  position: relative;
  overflow: hidden;

  p {
    word-wrap: break-word;
    max-width: 100%:
  }
`;

export const InputContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  gap: 0.25rem;

  button {
    border-radius: 100%;
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-size: 18px;

    span {
      display: none;
    }
  }
`;

export const TextInput = styled.input`
  flex: 1;
  padding: 0.5rem 0.75rem;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid gray;
  outline: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
`;
