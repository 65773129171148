import { useCallback, useState } from "react";
import { GameCard } from "../../../types/Card";
import { CrudClient } from "../../../api/CrudClient";
import { API_ROUTES } from "../../../api/routes";
import { AxiosError } from "axios";
import { toast } from "material-react-toastify";

export const useUpdateCard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  const updateCard = useCallback(
    async (id: string, card: Partial<GameCard>) => {
      setError(undefined);
      setLoading(true);
      try {
        const { data } = await CrudClient.put(API_ROUTES.CARD(id), card);
        setLoading(false);
        toast.success("Carta salva com sucesso.");
        return data;
      } catch (e) {
        const error = e as AxiosError<any, any>;
        error.message = error.response?.data?.message || error.message;
        setError(e as any);
      }
      setLoading(false);
    },
    []
  );

  return { error, updateCard, loading };
};
