import { useCallback, useEffect, useState } from "react";
import { CARD_TYPE, GameCard } from "../../../types/Card";
import { CrudClient } from "../../../api/CrudClient";
import { API_ROUTES } from "../../../api/routes";

export const useListCards = (type?: CARD_TYPE, skip?: boolean) => {
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState<GameCard[]>();

  const getCards = useCallback(async () => {
    setLoading(true);
    const { data } = await CrudClient.get(API_ROUTES.CARDS(type));
    setCards(data || []);
    setLoading(false);
  }, [type]);

  useEffect(() => {
    if (!skip) getCards();
  }, [getCards, skip]);

  return { cards, getCards, loading };
};
